import React from 'react';
import ToggleOrgWithKey from '../ToggleOrgWithKeys/ToggleOrgWithKeys';
import ToggleField from '../ToggleField/ToggleField';
import OptionOrgInput from '../OptionOrgInput/OptionOrgInput';
import OptionOrgSelect from '../OptionOrgSelect/OptionOrgSelect';
import OptionTabTooltip from './OptionTabTooltip';
import ReactSelect from "react-select";
import { useForm } from 'react-hook-form'
import Select from 'react-select';

function OptionTab({
  clientDetails,
  orgPartialName,
  orgResponseFor,
  continuous_access_response_for_list,
  capitalizeFirstLetter,
  changeNamesFromKeys,
  orgCustomFor,
  earlyResponseFor,
  customPdfFor,
  creditDecisionVista,
  months,
  setClientDetails,
  handleSetting,
  handleChange,
  creditDecisionCorpVista,
  submitHandle,
  configurationSettings,
  endpointsList,
  onEndpointChange,
  languageData
}) {
  const { register, handleSubmit, reset } = useForm();

  const languages = [
    { label: 'English', value: 'en' },
    { label: 'Swedish', value: 'sw' },
    { label: 'German', value: 'de' },
    { label: 'Spanish', value: 'es' },
  ]

  const handleOptions = (event, name) => {
    if (name === 'bank_connection') {
      setClientDetails(prevState => ({
        ...prevState,
        bank_connection: event.target.value,
      }))
    } else {
      setClientDetails(prevState => ({
        ...prevState,
        [name]: event.target.checked
      }))
    }
  }

  const requestModesHandler = (event, name) => {
    let setting = clientDetails.setting;
    setting.request_modes[name] = event.target.checked
    setClientDetails(prevState => ({
      ...prevState,
      setting: setting
    }))
  }

  const handleChangeSetting = (event) => {
    let setting = clientDetails.setting;
    const { name, value } = event.target;

    if (name === "used_bank_skip_time") {
      setting[name] = parseInt(value);
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }
    else {
      setting[event.target.name] = event.target.value
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }
  }

  const handleStorageSetting = (event) => {
    const { value } = event.target
    setClientDetails(prevState => ({
      ...prevState,
      storage_days: value
    }))
  }

  const getDescription = (key) => {
    if (!configurationSettings.data) {
      console.error('configurationSettings.data is undefined');
      return 'N/A';
    }

    const configObjects = configurationSettings.data.filter(item => item.key === key);
    return configObjects.length > 0 ? configObjects[0].description : 'N/A';
  }

  const handleEndpointChange = (selectedOption) => {
    const selectedEndpointId = selectedOption ? selectedOption.value : null;
    onEndpointChange(selectedEndpointId);
  };
  const serializeFile = (file, base64Content) => {

    return {
      file: base64Content,
      name: file.name,
      type: file.type,
    };
  }

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]); // Extract Base64 part
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file); // Read file as Base64
    });
  }

  const ApiData = async (data) => {
    if (data?.file_name?.length > 0) {
      let setting = clientDetails.setting;
      const base64Content = await convertFileToBase64(data.file_name[0]);
      const afterSeral = serializeFile(data.file_name[0], base64Content)
      setting.image_logo = afterSeral
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
      submitHandle()
      reset()
    } else {
      submitHandle()
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'rgb(0, 82, 204)' : 'white',
      color: state.isSelected ? 'rgb(0, 82, 204)' : 'black'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'rgb(0, 82, 204)',
      backgroundColor: "#bfdbfe"
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'rgb(0, 82, 204)',
      backgroundColor: "#bfdbfe",
      ':hover': {
        backgroundColor: "#bfdbfe",
        color: 'rgb(0, 82, 204)'
      }
    })
  };

  const multiLanguageHandler = (selected) => {
    let language = clientDetails?.language;
    const languageValues = selected?.map(item => item.value);
    language = languageValues;
    setClientDetails(prevState => ({
      ...prevState,
      language: language
    }))
  }


  return (
    <form id="options_details" className="hide" onSubmit={handleSubmit(ApiData)}>
      <p className='note-text'>* NOTE! Each option contains an option tooltip for clearer explanation of what each tool exactly does</p>
      <div className="detailed-box switch-row-box">
        <h4 className="headeing-switch switch-row table-row-title">Vista - Manual Request<br></br> (Bank flow pages)</h4>
        <div className="head-switch toggle-switch">
          <ToggleOrgWithKey keys={"Custom landing view"} id={"customized_view"} checked={clientDetails.customized_view} toggleFunction={handleOptions} showIcon={true} description={getDescription('customized_view')} isOptionsTabSelected={true}
          />
          <ToggleOrgWithKey keys={"Custom Success View"} id={"customized_result_view"} checked={clientDetails.customized_result_view} toggleFunction={handleOptions} showIcon={true} description={getDescription('customized_rezult_view')} isOptionsTabSelected={true} />
          <ToggleOrgWithKey keys={"Custom Consent View"} id={"customized_consent"} checked={clientDetails.setting.customized_consent} toggleFunction={handleSetting} showIcon={true} description={getDescription('customized_consent')} isOptionsTabSelected={true}
          />
          <ToggleOrgWithKey keys={"Show Default Ssn-SE market"} id={"show_ssn"} checked={clientDetails.show_ssn} toggleFunction={handleOptions} showIcon={true} description={getDescription('show_ssn')} isOptionsTabSelected={true} name={'show_ssn'} />
          <ToggleOrgWithKey keys={"Show Default Ssn"} id={"show_default_ssn"} checked={clientDetails.setting.show_default_ssn} toggleFunction={handleSetting} showIcon={true} description={getDescription('show_default_ssn')} isOptionsTabSelected={true} />
        </div>
        <div className="head-switch toggle-switch">
          {['hide_ssn_on_consent_page', 'single_selection_on_multi_bank_view'].map((keys, index) =>
            <ToggleField
              keys={keys}
              checked={clientDetails.setting[keys]}
              toggleFunction={handleSetting}
              capitalizeFirstLetter={capitalizeFirstLetter}
              key={index}
              showIcon={true}
              description={getDescription(keys)}
            />
          )}
          <OptionOrgInput type={"number"} name={"used_bank_skip_time"} keys={"Iframe - Hide banks for x amount of minutes"} value={clientDetails.setting.used_bank_skip_time} changeHandler={handleChangeSetting} showIcon={true} description={getDescription('used_bank_skip_time')} />
        </div>
      </div>
      <div className="detailed-box switch-row-box">
        <h4 className="headeing-switch switch-row table-row-title">Vista Accounts</h4>
        <div className="head-switch toggle-switch">
          {['email_template_enabled', 'sms_template_enabled'].map((keys, index) =>
            <ToggleField
              keys={keys}
              checked={clientDetails[keys]}
              toggleFunction={handleOptions}
              capitalizeFirstLetter={capitalizeFirstLetter}
              key={index}
              showIcon={true}
              description={getDescription(keys)}
            />
          )}
          {['certificate_column_customizable', 'two_factor_enabled', 'show_all_pending_requests'].map((keys, index) =>
            <ToggleField
              keys={keys}
              checked={clientDetails.setting[keys]}
              toggleFunction={handleSetting}
              capitalizeFirstLetter={capitalizeFirstLetter}
              key={index}
              showIcon={true}
              description={getDescription(keys)}
            />
          )}
          {['api', 'iframe', 'manual_request', 'generic_request', 'external_payload', 'partner_fetch', 'internal_payload', 'secondary_payload'].map((keys, index) =>
            <ToggleField
              keys={keys}
              checked={clientDetails?.setting.request_modes[keys]} s
              toggleFunction={requestModesHandler}
              capitalizeFirstLetter={capitalizeFirstLetter}
              key={index}
              showIcon={true}
              description={getDescription(keys)}
            />
          )}
        </div>
        <div className="head-switch toggle-switch">
          {['customer_enabled', 'corporate_enabled', 'business_rules_enabled'].map((keys, index) =>
            <ToggleField
              keys={keys}
              checked={clientDetails[keys]}
              toggleFunction={handleOptions}
              capitalizeFirstLetter={capitalizeFirstLetter}
              key={index}
              showIcon={true}
              description={getDescription(keys)}
            />
          )}
          {['enable_primary_type_customization'].map((keys, index) =>
            <ToggleField
              keys={keys}
              checked={clientDetails.setting[keys]}
              toggleFunction={handleSetting}
              capitalizeFirstLetter={changeNamesFromKeys}
              key={index}
              showIcon={true}
              description={getDescription(keys)}
            />
          )}
          <ToggleOrgWithKey keys={"Read only sub accounts"} id={"sub_account_readonly"} checked={clientDetails.setting.sub_account_readonly} toggleFunction={handleSetting} showIcon={true} description={getDescription('sub_account_readonly')} isOptionsTabSelected={true} />
          <h3 className="headeing-switch-light">
            <div className="keys-and-icon">
              Duration Of Data Storage
              <OptionTabTooltip showIcon={true} description={getDescription('storage_forever')} />
            </div>
            <div className="adjust-switcher">
              <div className="select-switch info-icon-drop">
                <label className="switch-box">
                  Permanent?
                  <input name='storage_forever' type="checkbox" checked={clientDetails.storage_forever} onChange={(e) => handleOptions(e, 'storage_forever')} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </h3>
          {!clientDetails.storage_forever &&
            <h3 className="headeing-switch-light dropdown-option-container">
              <div className="keys-and-icon">
                Storage Time
                <OptionTabTooltip showIcon={true} description={getDescription('storage_days')} />
              </div>
              <label className="form-group form-group-option">
                <input name="storage_time" className="form-control w-100" type="number" min="1" value={clientDetails.storage_days} onChange={(e) => handleStorageSetting(e)} />
              </label>
            </h3>
          }
        </div>
      </div>
      <div className="detailed-box switch-row-box">
        <h4 className="headeing-switch switch-row table-row-title">Multi Or Single Bank<br></br>Connection</h4>
        <div className="head-switch toggle-switch">
          <h3 className="headeing-switch-light">
            <div className="keys-and-icon">
              Manual Connection
              <OptionTabTooltip showIcon={true} description={getDescription('bank_connection')} />
            </div>
            <div className="form-group form-group-option" >
              <select className="form-control w-100 org-excludeDropdown" id="bank_connection" name='bank_connection' value={clientDetails.bank_connection} onChange={(e) => handleOptions(e, 'bank_connection')}>
                <option
                  className='admin-option'
                  key={Math.random()}
                  value='default'>
                  single bank
                </option>
                <option
                  className='admin-option'
                  key={Math.random()}
                  value='custom'>
                  multi bank
                </option>
              </select>
            </div>
          </h3>
          <h3 className="headeing-switch-light">
            <div className="keys-and-icon">
              Generic Connection
              <OptionTabTooltip showIcon={true} description={getDescription('generic_bank_connection')} />
            </div>
            <div className="form-group form-group-option" >
              <select className="form-control w-100 org-excludeDropdown" id="generic_bank_connection" name='generic_bank_connection' value={clientDetails.setting.generic_bank_connection} onChange={(e) => handleSetting(e, 'generic_bank_connection')}>
                <option
                  className='admin-option'
                  key={Math.random()}
                  value='default'>
                  single bank
                </option>
                <option
                  className='admin-option'
                  key={Math.random()}
                  value='custom'>
                  multi bank
                </option>
              </select>
            </div>
          </h3>
        </div>
        <div className="head-switch toggle-switch">
          <h3 className="headeing-switch-light">
            <div className="keys-and-icon">
              Iframe Connection
              <OptionTabTooltip showIcon={true} description={getDescription('iframe_bank_connection')} />
            </div>
            <div className="form-group form-group-option" >
              <select className="form-control w-100 org-excludeDropdown" id="iframe_bank_connection" name='iframe_bank_connection' value={clientDetails.setting.iframe_bank_connection} onChange={(e) => handleSetting(e, 'iframe_bank_connection')}>
                <option
                  className='admin-option'
                  key={Math.random()}
                  value='default'>
                  single bank
                </option>
                <option
                  className='admin-option'
                  key={Math.random()}
                  value='custom'>
                  multi bank
                </option>
              </select>
            </div>
          </h3>
          <h3 className="headeing-switch-light">
            <div className="keys-and-icon">
              API Connection
              <OptionTabTooltip showIcon={true} description={getDescription('api_bank_connection')} />
            </div>
            <div className="form-group form-group-option" >
              <select className="form-control w-100 org-excludeDropdown" id="api_bank_connection" name='api_bank_connection' value={clientDetails.setting.api_bank_connection} onChange={(e) => handleSetting(e, 'api_bank_connection')}>
                <option
                  className='admin-option'
                  key={Math.random()}
                  value='default'>
                  single bank
                </option>
                <option
                  className='admin-option'
                  key={Math.random()}
                  value='custom'>
                  multi bank
                </option>
              </select>
            </div>
          </h3>
        </div>
      </div>
      <div className="detailed-box switch-row-box">
        <h4 className="headeing-switch switch-row table-row-title">Others</h4>
        <div className="head-switch toggle-switch">
          <OptionOrgSelect keys={"Custom Partial"} name={"partial_name"} value={clientDetails.partial_name} options={orgPartialName} resetToDefault={""} changeHandler={handleChange} showIcon={true} description={getDescription('partial_name')} />
          <h3 className="headeing-switch-light">
            <div className="keys-and-icon">
              Custom Logo
            </div>
            <div className="form-group" style={{ width: "216px" }}>
              <div className="select-switch">
                <label className="switch-box">
                  <input type="file" accept="image/png, image/gif, image/jpeg" {...register('file_name')} />
                </label>
              </div>
            </div>
          </h3>
          <h3 className="headeing-switch-light">
            <div className="keys-and-icon">
              Refetch Transactions
              <OptionTabTooltip showIcon={true} description={getDescription('refetch_transactions_type')} />
            </div>
            <div className="form-group form-group-option" >
              <select className="form-control w-100 org-excludeDropdown" id="refetch_transactions_type" name='refetch_transactions_type' value={clientDetails.setting.refetch_transactions_type} onChange={(e) => handleSetting(e, "refetch_transactions_type")}>
                <option key={Math.random()} value='all'>All</option>
                <option key={Math.random()} value='new'>New</option>
              </select>
            </div>
          </h3>
          <h3 className="headeing-switch-light">
            <div className="keys-and-icon">
              Continuous Acces <br></br>Refetch Per Day
              <OptionTabTooltip showIcon={true} description={getDescription('refetch_limit')} />
            </div>
            <label className="form-group form-group-option" >
              <input name="refetch_limit" className="form-control w-100" type="number" min="0" value={clientDetails.setting.refetch_limit} onChange={(e) => handleChangeSetting(e)} />
            </label>
          </h3>
          {
            [
              { keys: "Response For List", name: "response_for", value: clientDetails.response_for, options: orgResponseFor, changeHandler: handleChange },
              { keys: "Custom Payload For", name: "custom_payload_for", value: clientDetails.setting.custom_payload_for, options: orgCustomFor, changeHandler: handleChangeSetting },
              { keys: "Early Response For", name: "early_response_for", value: clientDetails.setting.early_response_for, options: earlyResponseFor, changeHandler: handleChangeSetting },
              { keys: "Custom Pdf For", name: "custom_pdf_for", value: clientDetails.setting.custom_pdf_for, options: customPdfFor, changeHandler: handleChangeSetting },
              { keys: "Credit Decision Vista (Customer)", name: "credit_decision_vista", value: clientDetails.setting.credit_decision_vista, options: creditDecisionVista, changeHandler: handleChangeSetting },
              { keys: "Credit Decision Vista (Corporate)", name: "credit_decision_corporate", value: clientDetails.setting.credit_decision_corporate, options: creditDecisionCorpVista, changeHandler: handleChangeSetting },
            ].map((item, index) => {
              const description = getDescription(item.name);
              return (
                <OptionOrgSelect keys={item.keys} name={item.name} value={item.value} options={item.options} resetToDefault={"Reset to default"} changeHandler={item.changeHandler} showIcon={true} key={index} description={description !== '' ? description : 'N/A'}
                />
              );
            })
          }
          <OptionOrgSelect keys={"Fetch Data For Months"} name={"fetch_data_for_months"} value={clientDetails.setting.fetch_data_for_months} options={months} resetToDefault={"Reset to default"} changeHandler={handleChangeSetting} showIcon={true} description={getDescription('fetch_data_for_months')} />
          <OptionOrgSelect keys={"Session Storage For"} name={"session_storage_for"} value={clientDetails.setting.session_storage_for} options={[[1, 1], [2, 2], [3, 3], [4, 4], [5, 5], [6, 6], [7, 7], [15, 15], [30, 30], [45, 45], [60, 60], [90, 90], [180, 180]]} resetToDefault={"Reset to default"} changeHandler={handleChangeSetting} showIcon={true} description={getDescription('session_storage_for')} />
          <h3 className="headeing-switch-light">
            <div className="keys-and-icon">
              Languages
              <OptionTabTooltip showIcon={true} description={getDescription('languages')} />
            </div>
            <div className="adjust-switcher">
              <label className="switch-box">
                <Select
                  closeMenuOnSelect={false}
                  onChange={multiLanguageHandler}
                  isMulti
                  options={languages}
                  styles={customStyles}
                  defaultValue={languageData}
                />
              </label>
            </div>
          </h3>
          <ToggleField keys={"customized_flow"} checked={clientDetails.setting.customized_flow} toggleFunction={handleSetting} capitalizeFirstLetter={changeNamesFromKeys} showIcon={true} description={getDescription('customized_flow') !== '' ? getDescription('customized_flow') : 'N/A'} />
          <h3 className="headeing-switch-light">
            <div className="keys-and-icon">
              Administrative Officer
              <OptionTabTooltip showIcon={true} description={getDescription('employee') !== '' ? getDescription('employee') : 'N/A'} />
            </div>
            <div className="adjust-switcher">
              <div className="select-switch">
                <label className="switch-box">
                  <input type="checkbox" checked={clientDetails.setting.certificate_delete_ability.employee} onChange={(e) => handleSetting(e, 'certificate_delete_ability')} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </h3>
          {
            ['generate_client_reference_id', 'joint_applicant_requests_activated', 'display_env_on_manual_requests', 'display_ssn_in_request_log'].map((keys, index) => <ToggleField keys={keys} checked={clientDetails.setting[keys]} toggleFunction={handleSetting} capitalizeFirstLetter={changeNamesFromKeys} key={index} showIcon={true} description={getDescription(keys) !== '' ? getDescription(keys) : 'N/A'} />)
          }
          {
            ['ip_restricted_login', 'dummy_account'].map((keys, index) => <ToggleField keys={keys} checked={clientDetails[keys]} toggleFunction={handleOptions} capitalizeFirstLetter={changeNamesFromKeys} key={index} showIcon={true} description={getDescription(keys)} />)
          }
          {
            ['seprate_account_and_transaction_in_response', 'sso_enabled', 'gross_income_enabled', 'status_process_cancelled', 'email_enabled', 'sms_enabled', 'parent_id_in_custom_response'].map((keys, index) => <ToggleField keys={keys} checked={clientDetails.setting[keys]} toggleFunction={handleSetting} capitalizeFirstLetter={capitalizeFirstLetter} key={index} showIcon={true} description={getDescription(keys)} />)
          }
        </div>
        <div className="head-switch toggle-switch">
          {
            ['continuous_access_enabled', 'early_response', 'enable_cerificate_sharing', 'default_status_true', 'certificate_column_customizable'].map((keys, index) => {
              const description = getDescription(keys);
              return (
                <ToggleField keys={keys} checked={clientDetails.setting[keys]} toggleFunction={handleSetting} capitalizeFirstLetter={changeNamesFromKeys} key={index} showIcon={true} description={description !== '' ? description : 'N/A'} />);
            })
          }
          {
            ['skip_used_banks', 'verify_transaction_count_on_accept', 'include_organization_number_in_response', 'iframe_token', 'case_id_as_client_reference_id', 'include_provider_id_in_response', 'enable_client_reference_id', 'validate_unique_client_reference_id', 'client_reference_id_success_view', 'enable_iframe_resizer_plugin'].map((keys, index) => {
              const description = getDescription(keys);
              return (
                <ToggleField keys={keys} checked={clientDetails.setting[keys]} toggleFunction={handleSetting} capitalizeFirstLetter={changeNamesFromKeys} key={index} showIcon={true} description={description !== '' ? description : 'N/A'} />);
            })
          }
          <ToggleOrgWithKey keys={"Download Request Log CSV"} id={"download_request_log_csv"} checked={clientDetails.setting.download_request_log_csv} toggleFunction={handleSetting} showIcon={true} description={getDescription('download_request_log_csv')} isOptionsTabSelected={true} />
          <ToggleOrgWithKey keys={"Hide Request Log Icons"} id={"hide_request_log_icons"} checked={clientDetails.setting.hide_request_log_icons} toggleFunction={handleSetting} showIcon={true} description={getDescription('hide_request_log_icons')} isOptionsTabSelected={true} />
          <ToggleOrgWithKey keys={"Chat Support"} id={"chat_support"} checked={clientDetails.setting.chat_support} toggleFunction={handleSetting} showIcon={true} description={getDescription('chat_support')} isOptionsTabSelected={true} />
          <ToggleOrgWithKey keys={"Time From Accepted To Opened"} id={"show_certificate_opened_time"} checked={clientDetails.setting.show_certificate_opened_time} toggleFunction={handleSetting} showIcon={true} description={getDescription('show_certificate_opened_time')} isOptionsTabSelected={true} />
          <ToggleOrgWithKey keys={"Display change decision status access setting"} id={"credit_decision_editable"} checked={clientDetails.setting.credit_decision_editable} toggleFunction={handleSetting} showIcon={true} description={getDescription('credit_decision_editable')} isOptionsTabSelected={true} />
          <ToggleOrgWithKey keys={"Display show healthcheck dashboard access setting"} id={"healthcheck_dashboard_enabled"} checked={clientDetails.setting.healthcheck_dashboard_enabled} toggleFunction={handleSetting} showIcon={true} description={getDescription('healthcheck_dashboard_enabled')} isOptionsTabSelected={true} />
          {
            ['show_type_on_request_form', 'show_other_on_request_form', 'account_verification_with_balance', 'prevent_ios_auto_zoom', 'enable_responsiveness','remove_double_scroll_css','dropdown_in_consent_text'].map((keys, index) => {
              const description = getDescription(keys);
              return (
                <ToggleField keys={keys} checked={clientDetails.setting[keys]} toggleFunction={handleSetting} capitalizeFirstLetter={changeNamesFromKeys} key={index} showIcon={true} description={description !== '' ? description : 'N/A'} />);
            })
          }
          <h3 className="headeing-switch-light dropdown-option-container">
            <div className="keys-and-icon">
              Endpoint
              <OptionTabTooltip showIcon={true} description={getDescription('analytics_endpoint')} />
            </div>
          <ReactSelect
            onChange={handleEndpointChange}
            options={endpointsList.map((endpoint) => ({
              value: endpoint.id,
              label: endpoint.name,
            }))}
            placeholder="Select endpoint"
            isClearable={true}
            noOptionsMessage={() => "No endpoints found"}
            className="org-select-endpoint-dropdown"
            value={
              clientDetails.analytics_endpoint_id ? endpointsList.find(endpoint => endpoint.id === clientDetails.analytics_endpoint_id)? { 
                value: clientDetails.analytics_endpoint_id, 
                label: endpointsList.find(endpoint => endpoint.id === clientDetails.analytics_endpoint_id).name }: null : null}
           />
          </h3>
        </div>
      </div >
      <div className="create-client-button">
        <button type='submit'>Update</button>
      </div>
    </form>
  );
}

export default OptionTab;