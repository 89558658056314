import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setdisplayFlash } from "../../../Utils/Common";
import FlashMessage from "../../../Components/FlashMessage/FlashMessage";



function AgreementTab({
  clientDetails,
  agreementDates,
  handleStartDateAgreement,
  handleEndDateAgreement,
  handleRunUntil,
  handleChange,
  handleAttachmentChange,
  agreementData,
  uploadIcon,
  calendarIcon,
  submitHandle
}) {
  const [flashMessage, setFlashMessage] = useState(false);
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");


  const agreenmentUpdateHandler = () => {
    if (agreementDates.agreement_start_date > agreementDates.agreement_end_date) {
      openFlash("", "Error", 'Start date cannot be later than the end date.');
    } else {
      submitHandle()
    }
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }
  return (
    <div>
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      <div id="agreementterms_details" className="hide">
        <div className="detail-two-box">
          <div className="details-left details-full-box">
            <h3 className="headeing-switch">
              Start Date For Agreement
              <div className="input-sets">
                <div id="datepicker" className="input-group date" data-date-format="mm-dd-yyyy">
                  <DatePicker
                    selected={agreementDates.agreement_start_date}
                    onChange={(date) => handleStartDateAgreement(date)}
                    placeholderText='Choose Start Date'
                  />
                  <span className="input-group-addon org-datepicker-agreement"><img src={calendarIcon} alt="calendarIcon" /></span>
                </div>
              </div>
            </h3>
            <h3 className="headeing-switch">
              End Date For Agreement
              <div className="input-sets">
                <div id="datepicker01" className="input-group date" data-date-format="mm-dd-yyyy">
                  <DatePicker
                    selected={agreementDates.agreement_end_date}
                    onChange={(date) => handleEndDateAgreement(date)}
                    placeholderText='Choose Date'
                  />
                  <span className="input-group-addon org-datepicker-agreement"><img src={calendarIcon} alt="calendarIcon" /></span>
                </div>
              </div>
            </h3>
            <h3 className="headeing-switch-light">
              Run Until Terminate By Client
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails.run_until_terminate} onChange={(e) => handleRunUntil(e)} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </h3>
            <h3 className="headeing-switch-light form-group-flex">
              Cancellation Period
              <div className="form-group">
                <input type="text" value={clientDetails.cancellation_period} className="form-control" placeholder="Enter no. of months" name='cancellation_period' onChange={(e) => handleChange(e)} />
              </div>
            </h3>
          </div>
          <div className="details-right details-full-box">
            <div className="browse-file">
              <div className="browse-choose-file">
                <input type="file" name="media" id="uploadFile" onChange={(e) => handleAttachmentChange(e, 0)} accept="*" />
                <img src={uploadIcon} alt="" />
                {agreementData && agreementData.agreement_file_name !== '' ? '' : clientDetails && clientDetails.agreement}
              </div>
              Upload agreement as PDF,XLS,XLSX
              <br />
              <br />
              {
                agreementData && agreementData.agreement_file_name ? agreementData.agreement_file_name :
                  clientDetails && clientDetails.agreement_url && (
                    <a href={'/' + clientDetails.agreement_url} download>Download Agreement</a>
                  )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="create-client-button">
        <button onClick={() => agreenmentUpdateHandler()}>Update</button>
      </div>
    </div>
  );
}

export default AgreementTab;
