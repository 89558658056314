import React from 'react';
import './editServiceSetting.css'
import { useState } from "react";


const EditServiceSetting = (props) => {
  const [orggId, setOrggId] = useState(props?.serviceSettingFilter?.filter_data?.organizations_list);
  const [servicesId, setServicesId] = useState(props?.serviceSettingFilter?.filter_data?.services_list);
  const [countris, setCountris] = useState(props?.serviceSettingFilter?.filter_data?.countries_list);
  const [certificateType, setCertificateType] = useState(props?.data?.certificate_type?.toString());
  const [env, setEnv] = useState(props?.data?.env?.toString());
  const [labelization, setLabelization] = useState(props?.data?.labelization?.toString());
  const [active, setActive] = useState(props?.data?.active?.toString());
  const [brokerApplication, setBrokerApplication] = useState(props?.data?.broker_application?.toString())
  const [ssnValidation, setSsnValidation] = useState(props?.data?.ssn_validation?.toString())
  const [selectedServiceId, setSelectedServiceId] = useState(props?.data?.service_id);
  const [selectedCountry, setSelectedCountry] = useState(props?.data?.country_id);
  const [selectedBankAuthentication, setSelectedBankAuthentication] = useState(props?.data.bank_authentication_approach?.toString());
  const [service, setService] = useState([{ key: "client_id", value: props?.data?.credentials.client_id }, { key: "client_secret", value: props?.data?.credentials.client_secret }, { key: "pem_file_key", value: props?.data?.credentials.pem_file_key }]);
  const [orgName, setOrgName] = useState(props.data?.organization_id?.toString())
  let id = props?.data?.id;
  const [categorizationMode, setCategorizationMode] = useState(props?.data.categorization_mode?.toString())

  const submitHandle = async (e) => {
    e.preventDefault()
    let credentialsData = {};
    service.forEach(item => {
      if (item.value) {
        credentialsData = {
          ...credentialsData, [item.key]: item.value
        }
      }
    })

    let countryIndex = countris.findIndex(item => item.id === selectedCountry)
    const data = {
      id: id,
      organization_id: orgName,
      service_id: Number(selectedServiceId),
      country_id: selectedCountry,
      country_name: countris[countryIndex].name,
      active: JSON.parse(active.replace(/ /g, "")),
      certificate_type: certificateType,
      env: env,
      labelization: JSON.parse(labelization.replace(/ /g, "")),
      broker_application: JSON.parse(brokerApplication.replace(/ /g, "")),
      ssn_validation: JSON.parse(ssnValidation.replace(/ /g, "")),
      bank_authentication_approach: selectedBankAuthentication,
      credentials: credentialsData,
      categorization_mode: categorizationMode
    }
    props.handleUpdate(data)
  }

  function humanize(str) {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }


  const getOrgName = (data, id) => {
    const val = data.filter(org => org.id == id)
    return `${val[0]?.name}`
  }


  return (
    <div className='EditPopup'>
      <div className='EditPopup_inner'>
        <h3>Edit Service Setting</h3>
        <form onSubmit={submitHandle}>
          <div className="row">
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Organization</label>
                <input className="select-filter-service-settings" value={`${getOrgName(orggId, orgName)}(${orgName})`} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Service</label>
                <input className="select-filter-service-settings" value={humanize(getOrgName(servicesId, selectedServiceId))} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Country</label>
                <input className="select-filter-service-settings" value={humanize(getOrgName(countris, Number(selectedCountry)))} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Certificate Type</label>
                <input className="select-filter-service-settings" value={humanize(certificateType)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>ENV</label>
                <input className="select-filter-service-settings" value={humanize(env)} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Active</label>
                <select className="select-filter-service-settings" value={active} onChange={e => setActive(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Labelization</label>
                <select className="select-filter-service-settings" value={labelization} onChange={e => setLabelization(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Bank Authentication Approach</label>
                <select className="select-filter-service-settings" value={selectedBankAuthentication} onChange={e => setSelectedBankAuthentication(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="decoupled">Decoupled</option>
                  <option value="redirect">Redirect</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Broker Application</label>
                <select className="select-filter-service-settings" value={brokerApplication} onChange={e => setBrokerApplication(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Ssn Validation</label>
                <select className="select-filter-service-settings" value={ssnValidation} onChange={e => setSsnValidation(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Categorization Mode</label>
                <select className="select-filter-service-settings" value={categorizationMode} onChange={e => setCategorizationMode(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="ruby">Ruby</option>
                  <option value="python">Python</option>
                </select>
              </div>
            </div>
          </div>
          <div className="modal-btns">
            <button className='closeDelete-update' style={{ marginLeft: '0px' }} onClick={props.closeDelete}>Cancel</button>
            <button className='confirm-update' type='submit'>Confirm</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditServiceSetting;