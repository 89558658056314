import React, { useState } from "react";
import logo from "../../assets/images/kreditz_new_logo.svg";
import { setdisplayFlash, getdisplayFlash } from "../../Utils/Common";
import { useDispatch } from "react-redux";
import FlashMessage from "../../Components/FlashMessage/FlashMessage";
import { forgetPassword } from "../../Redux/Actions/Auth";
import "./forgetPassword.css";

const ForgetPassword = (props) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email !== "") {
      const data = {
        email: email.replace(/ /g, ""),
      };

      const { status, message } = await dispatch(forgetPassword(data));
      if (status) {
        openFlash("tick", "Success!", "If the email you have entered exists in our system, we will send a link to reset your password.");
      } else {
        openFlash("alert", "Error!", message);
      }
    } else {
      openFlash("alert", "Error!", "Please enter your email");
    }
  };

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }
  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }

  return (
    <div>
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      <div className="login_form">
        <div className="outer-login">
          <div className="inner-login">
            <div className="logo">
              <img src={logo} alt="logo_new" style={{ width: "150" }} />
            </div >
            <h4>Forget Password</h4>
            <p className="forget-title">Enter your <span>EMAIL</span> and we well send you a link to <span>RESET YOUR PASSWROD</span></p>
            <div className="login-form">
              <input type="email" name="email" id="forget_email" placeholder="example@kreditz.com" className="form-control" required="required" onChange={(e) => setEmail(e.target.value)} />
              <button type="button" onClick={handleSubmit}>Submit</button>
              <div className="forget-link">
                <a href="/login">Back to login</a>
              </div>
            </div>
          </div >
        </div >
      </div >
    </div >
  )

}

export default ForgetPassword;
