import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import CreatedVsActiveClientsChart from "./CreatedVsActiveClientsChart";
import CreatedVsActiveClientsPieChart from "./CreatedVsActiveClientsPieChart";
import { getCreatedVsActiveCustomersData } from '../../../Redux/Actions/Statistics';
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import "./createdVsActive.scss";

const CreatedVsActiveOverview = () => {
  const dispatch = useDispatch();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [data, setData] = useState({});
  const [totalClients, setTotalClients] = useState([]);
  const [error, setError] = useState(null);

  const fetchCreatedVsActiveData = useCallback(async (year) => {
    try {
      setError(null);
      dispatch(setLoaderSpinner(true));
      const response = await dispatch(getCreatedVsActiveCustomersData(year));

      if (!response?.data) {
        throw new Error('No data received from server');
      }
      setData(response.data.monthly_data_by_year || {});
      setTotalClients(response.data.data || []);
    } catch (error) {
      setError(error.message);
    } finally {
      dispatch(setLoaderSpinner(false));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchCreatedVsActiveData(selectedYear);
  }, [selectedYear, fetchCreatedVsActiveData]);

  const generateYearOptions = () => {
    const baseYear = 2023;
    const currentYear = new Date().getFullYear();
    const endYear = currentYear;
    const years = [];

    for (let year = baseYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const getClientCount = (type) => {
    const yearData = totalClients?.find(item => item?.year === Number(selectedYear));
    return yearData?.[type] || 0;
  };

  if (error) {
    return (<div className="statistics-container">Error loading data: {error}</div>);
  }

  return (
    <div className="statistics-container">
      <div className="lower-header">
        <h3>Created vs Active</h3>
        <div className="date-picker-container">
          <select
            className="date-form-box"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            style={{ cursor: "pointer" }}
          >
            {generateYearOptions().map(year => (
              <option key={year} value={year.toString()}>{year}</option>
            ))}
          </select>
          <div className="ld-calendar-icon">
            <FontAwesomeIcon icon={faCalendar} color="#e8edfa" />
          </div>
        </div>
      </div>
      <section className="accepted-requests-section">
        {data?.[selectedYear] ? (
          <CreatedVsActiveClientsChart data={data[selectedYear]} />
        ) : null}
      </section>
      <div className="total-clients-box">
        <div className="request-info">
          <p>Created clients - {selectedYear}</p>
          <h4>{getClientCount('created_clients')}</h4>
        </div>
        <div className="request-info">
          <p>Active clients - {selectedYear}</p>
          <h4>{getClientCount('active_clients')}</h4>
        </div>
      </div>
      <section className="accepted-requests-section">
        {(getClientCount('created_clients') > 0 || getClientCount('active_clients') > 0) ? (
          <CreatedVsActiveClientsPieChart
            createdClients={getClientCount('created_clients')}
            activeClients={getClientCount('active_clients')}
          />
        ) : null}
      </section>
    </div>
  );
};

export default CreatedVsActiveOverview;