import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAdminLogsList } from "../../Redux/Actions/AdminLogs";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import _ from "lodash";
import ServiceSettingCreated from "./Categories/ServiceSettingCreated";
import OrganizationProviderCreated from "./Categories/OrganizationProviderCreated";
import ReportLog from "./Categories/ReportLog";
import KreditzProviderUpdated from "./Categories/KreditzProviderUpdated";
import CertificateDeletion from "./Categories/CertificateDeletion";
import ApiAccessCode from "./Categories/ApiAccessCode";
import DemoAccessCodeSent from "./Categories/DemoAccessCodeSent";
import OrganizationProviderDeleted from "./Categories/OrganizationProviderDeleted";
import ServiceSettingUpdated from "./Categories/ServiceSettingUpdated";

// STYLES
import "./adminLogsList.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

// LIBRARIES
import DateRangePicker from "react-bootstrap-daterangepicker";
import ReactPaginate from "react-paginate";
import moment from "moment";
import "boxicons";

const prettySelectLabel = (eventType) => {
  return eventType
    .replace("logged_in", "Logged In")
    .replace("logout", "Logged Out")
    .replace("user_created", "User Created")
    .replace("user_updated", "User Updated")
    .replace("user_deleted", "User Deleted")
    .replace("organization_updated", "Organization Updated")
    .replace("organization_created", "Organization Created")
    .replace("organization_deleted", "Organization Deleted")
    .replace("report_created", "Report Created")
    .replace("report_deleted", "Report Deleted")
    .replace("report_downloaded", "Report Downloaded")
    .replace("organization_provider_deleted", "Organization Provider Deleted")
    .replace("organization_provider_updated", "Organization Provider Updated")
    .replace("organization_provider_created", "Organization Provider Created")
    .replace("certificate_deleted", "Certificate Deleted")
    .replace("kreditz_provider_updated", "Kreditz Provider Updated")
    .replace("service_setting_created", "Service Setting Created")
    .replace("service_setting_updated", "Service Setting Updated")
    .replace("api_access_code_generated", "API Documentation Access Code Generated")
    .replace("demo_access_code_generated", "Demo Access Code Generated")
    .replace("demo_access_code_sent", "Demo Access Code Sent")
    .replace("global_setting_updated", "Global Setting Updated")
};

var COUNTRIES = [];

const AdminLogsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [total_pages, setTotal_pages] = useState("");
  const [adminLogs, setAdminLogs] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [users, setUsers] = useState([]);

  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedQuery, setSelectedQuery] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);

  const fetchAdminList = async (params) => {
    await dispatch(setLoaderSpinner(true));
    const { requestSuccess, data } = await dispatch(getAdminLogsList(params));
    if (data) {
      setTotal_pages(data.total_pages);
      setAdminLogs(
        data.admin_logs.filter((item) => {
          return (
            item.event_type !== "update_certificate_button_clicked" &&
            item.event_type !== "refetch_transactions_button_clicked"
          );
        })
      );
      COUNTRIES = data.countries;
      setEventTypes(data.event_types);

      if (!params.query) {
        const usersData = data.users_list.map((user) => ({
          name: user[0],
          id: user[1],
        }));
        setUsers(usersData);
      }
    }
    await dispatch(setLoaderSpinner(false));
    if (!requestSuccess) {
      history.push("/login");
    }
  };

  const selectPageHandler = (event) => {
    const currentPage = event.selected + 1;
    setSelectedPage(currentPage);
  };

  const selectQueryHandler = (event) => {
    const currentQuery = event.target.value;
    setSelectedQuery(currentQuery);
  };

  const handleSearch = () => {
    setSelectedEvent("");
    setSelectedUser("");

    const filters = {
      admin_user_id: "",
      event_type: null,
      daterange: selectedDate,
      commit: "Go",
      query: selectedQuery,
      page: 1
    };
    fetchAdminList(filters);
  };

  const selectEventHandler = (event) => {
    const currentEvent = event.target.value;
    if (currentEvent === "selectEventType" || currentEvent === "all") {
      setSelectedEvent("");
      return;
    }
    setSelectedEvent(currentEvent);
  };

  const selectUserHandler = (event) => {
    const currentUser = event.target.value;
    setSelectedUser(currentUser === "selectUser" ? "" : currentUser);
  };

  const selectDateHandler = (_, picker) => {
    const startDateformatted = moment(picker.startDate).format("DD/MM/YYYY");
    const endDateformatted = moment(picker.endDate).format("DD/MM/YYYY");
    setSelectedDate(`${startDateformatted} - ${endDateformatted}`);
  };

  const updateFormHandler = (e) => {
    e && e.preventDefault();
    const filters = {
      admin_user_id: selectedUser,
      event_type: selectedEvent ? [selectedEvent] : null,
      daterange: selectedDate,
      commit: "Go",
      query: selectedQuery,
      page: selectedPage,
    };
    fetchAdminList(filters);
  };

  useEffect(() => {
    updateFormHandler();
  }, [selectedPage]);
  const getValue = (value) => {
    return value === undefined ? "" : value;
  };
  return (
    <div className="page-container">
      <div className="admin-table-wrapper">
        <h3 className="admin-h3">Admin Logs</h3>
        <div className="white-bg">
          <form className="form-wrapper">
            <div className="div">
              {/* --- CALENDAR --- */}
              <div className="section-calendar">
                <box-icon
                  className="box-icon"
                  color="#6c757d"
                  size="1.6em"
                  name="calendar-alt"
                ></box-icon>
                <DateRangePicker
                  onApply={selectDateHandler}
                  initialSettings={{ locale: { format: "DD/MM/YYYY" } }}
                >
                  <input
                    type="text"
                    id="date-range-picker"
                    className="form-control"
                  />
                </DateRangePicker>
              </div>
              {/* --- SELECT USER --- */}
              <div className="section-select-user">
                <select
                  id="select-user"
                  className="form-control"
                  value={selectedUser}
                  onChange={selectUserHandler}
                >
                  <option className="admin-option" value="selectUser">
                    Select User
                  </option>
                  {users.map((user, index) => (
                    <option
                      className="admin-option"
                      key={Math.random()}
                      value={user.id}
                    >
                      {user.name}
                    </option>
                  ))}
                  ;
                </select>
              </div>
              {/* --- SELECT EVENT TYPE --- */}
              <div className="section-select-event-type">
                <select
                  id="select-event-type"
                  className="form-control"
                  value={selectedEvent}
                  onChange={selectEventHandler}
                >
                  <option className="admin-option" value="selectEventType">
                    Select Event Type
                  </option>
                  <option className="admin-option" value="all">
                    All
                  </option>
                  {eventTypes &&
                    eventTypes.map((eventType, index) => (
                      <option
                        label={prettySelectLabel(eventType)}
                        value={eventType}
                        className="admin-option"
                        key={Math.random()}
                      />
                    ))}
                </select>
              </div>
              {/* --- GO BUTTON --- */}
              <div className="section-btn-go">
                <button
                  type="button"
                  onClick={updateFormHandler}
                  id="admin-btn-go"
                  className="btn btn-primary"
                >
                  Go
                </button>
              </div>
            </div>
            {/* --- SEARCH --- */}
            <div className="section-search">
              <input
                id="search"
                placeholder="Enter username"
                autoComplete="off"
                type="text"
                value={selectedQuery}
                onChange={selectQueryHandler}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>
          </form>
          <table className="table" width="100%" style={{ margin: 0 }}>
            <thead className="admin-thead">
              <tr>
                <td id="date-time">Date/time</td>
                <td id="user">User</td>
                <td id="activity">Activity</td>
                <td id="entity-affected">Entity Affected</td>
              </tr>
            </thead>
          </table>
          <div className="organizations-thead-scroll-y">
            <table className="table " width="100%">
              <tbody className="admin-tbody">
                {adminLogs.map((adminLog, index) => {
                  return (
                    <tr
                      key={Math.random()}
                      id={`al-${adminLog.id}`}
                    >
                      <td className="admin-td text" id="date-time">
                        {moment(adminLog.created_at).format(
                          "D MMM YYYY, h:mm:ss A"
                        )}
                      </td>
                      <td className="admin-td text" id="user">
                        {adminLog.user_name}
                      </td>
                      <td className="admin-td text" id="activity">
                        {adminLog.event_type
                          .replace("logged_in", "Logged In")
                          .replace("logout", "Logged Out")
                          .replace("user_created", "User Created")
                          .replace("user_updated", "User Updated")
                          .replace("user_deleted", "User Deleted")
                          .replace("organization_updated", "Organization Updated")
                          .replace("organization_created", "Organization Created")
                          .replace("organization_deleted", "Organization Deleted")
                          .replace("report_created", "Report Created")
                          .replace("report_deleted", "Report Deleted")
                          .replace("report_downloaded", "Report Downloaded")
                          .replace("organization_provider_deleted", "Organization Provider Deleted")
                          .replace("organization_provider_updated", "Organization Provider Updated")
                          .replace("organization_provider_created", "Organization Provider Created")
                          .replace("certificate_deleted", "Certificate Deleted")
                          .replace("kreditz_provider_updated", "Kreditz Provider Updated")
                          .replace("api_access_code_generated", "API Documentation Access Code Generated")
                          .replace("demo_access_code_generated", "Demo Access Code Generated")
                          .replace("demo_access_code_sent", "Demo Access Code Sent")
                          .replace("service_setting_created", "Service Setting Created")
                          .replace("service_setting_updated", "Service Setting Updated")
                          .replace("global_setting_updated", "Global Setting Updated")
                        }
                      </td>
                      <td className="admin-td text" id="entity-affected">
                        {(() => {
                          //USER DELETED
                          if (adminLog.column_affected.deleted_at) {
                            return (
                              <div key={Math.random()}>
                                <p className="al-column-affected-p">—</p>
                              </div>
                            );
                          }

                          // USER CREATED
                          if (
                            adminLog.column_affected.name &&
                            adminLog.column_affected.role &&
                            adminLog.column_affected.email
                          ) {
                            return (
                              <div
                                key={Math.random()}
                              >
                                <p className="al-column-affected-p">
                                  <strong>Name: </strong>
                                  {adminLog.column_affected.name[0] === null
                                    ? "' '"
                                    : ""}
                                  <span> → </span>
                                  {adminLog.column_affected.name[1]}
                                </p>
                                <p className="al-column-affected-p">
                                  <strong>Role: </strong>
                                  {adminLog.column_affected.role[0] === null
                                    ? "' '"
                                    : ""}
                                  <span> → </span>
                                  {adminLog.column_affected.role[1]}
                                </p>
                                <p className="al-column-affected-p">
                                  <strong>Email: </strong>
                                  {adminLog.column_affected.email[0] === null
                                    ? "' '"
                                    : ""}
                                  <span> → </span>
                                  {adminLog.column_affected.email[1]}
                                </p>
                              </div>
                            );
                          }
                          // USER UPDATED
                          if (
                            adminLog.column_affected.name &&
                            adminLog.column_affected.role
                          ) {
                            return (
                              <div
                                key={Math.random()}
                              >
                                <p className="al-column-affected-p">
                                  <strong>Name: </strong>
                                  {adminLog.column_affected.name[0]}
                                  <span> → </span>
                                  {adminLog.column_affected.name[1]}
                                </p>
                                <p className="al-column-affected-p">
                                  <strong>Role: </strong>
                                  {adminLog.column_affected.role[0]}
                                  <span> → </span>
                                  {adminLog.column_affected.role[1]}
                                </p>
                              </div>
                            );
                          }

                          if (
                            adminLog.column_affected.name &&
                            adminLog.column_affected.email
                          ) {
                            return (
                              <div key={Math.random()}>
                                <p className="al-column-affected-p">
                                  <strong>Name: </strong>
                                  {adminLog.column_affected.name[0]}
                                  <span> → </span>
                                  {adminLog.column_affected.name[1]}
                                </p>
                                <p className="al-column-affected-p">
                                  <strong>Email: </strong>
                                  {adminLog.column_affected.email[0]}
                                  <span> → </span>
                                  {adminLog.column_affected.email[1]}
                                </p>
                              </div>
                            );
                          }

                          if (adminLog.column_affected.role) {
                            return (
                              <div key={Math.random()}>
                                <p className="al-column-affected-p">
                                  <strong>Role: </strong>
                                  {adminLog.column_affected.role[0]}
                                  <span> → </span>
                                  {adminLog.column_affected.role[1]}
                                </p>
                              </div>
                            );
                          }

                          // ORGANIZATION UPDATED
                          const entityAffectedComponents = [];

                          if (adminLog.organization_name) {
                            const stringParts = [];
                            stringParts.push(`${adminLog.organization_name}`);
                            const resultString = stringParts;
                            entityAffectedComponents.push(
                              <div
                                key={Math.random()}
                              >
                                <p className="al-column-affected-p">
                                  <strong>Name: </strong>
                                  {resultString}
                                </p>
                              </div>
                            )
                          }

                          if (adminLog.object_id) {
                            const stringParts = [];
                            stringParts.push(`${adminLog.object_id}`);
                            const resultString = stringParts;
                            entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'>
                                  <strong>ID: </strong>
                                  {resultString}
                                </p>
                              </div>
                            ))
                          }

                          let key = adminLog?.column_affected && Object.keys(adminLog?.column_affected)[1];
                          let keys = adminLog?.column_affected && Object.keys(adminLog?.column_affected).filter(key => key !== 'updated_at');
                          const getKeyName = (keyName) => {
                            switch (keyName) {
                              case "iframe_bank_connection":
                                return "Multibank Connection (Iframe)"
                              case "bank_connection":
                                return "Multibank Connection - Manual request"
                              case "corporate_overview":
                                return "Corporate Overview"
                              case "account_type":
                                return "account-type"
                              case "delivery_via":
                                return "Delivery via"
                              case "standard_rule_enabled":
                                return "Standart Rule Enabled"
                              case "credit_decision_rule_enabled":
                                return "Credit Decision Rule Enabled"
                              case "sms_sender_name":
                                return "SMS Sender Name"
                              case "contact_phone":
                                return "Company Phone"
                              case "contact_email":
                                return "Company Email"
                              case "customized_response":
                                return "Customized Response"
                              case "organization_type":
                                return "Organization Type"
                              case "end_user_display_name":
                                return "End User Display Name"
                              case "email_template_enabled":
                                return "Email Template Enabled"
                              case "enable_primary_type_customization":
                                return "Custom Income type - Categories"
                              case "customized_consent":
                                return "Customized consent"
                              case "continuous_access_enabled":
                                return "Continuous Access Enabled"
                              case "early_response":
                                return "Early Response"
                              case "enable_cerificate_sharing":
                                return "Enable Cerificate Sharing"
                              case "generic_bank_connection":
                                return "Generic Bank Bonnection"
                              case "two_factor_enabled":
                                return "Two Factor Enabled"
                              case "skip_used_banks":
                                return "Iframe - Hide connected bank"
                              case "used_bank_skip_time":
                                return "Iframe - Hide banks for x amount of minutes"
                              case "api_bank_connection":
                                return "Multibank Connection - API"
                              case "sub_account_readonly":
                                return "Sub account readonly"
                              case "ip_restricted_login":
                                return "Ip Restricted Login"
                              case "certificate_column_customizable":
                                return "Custom Request Log"
                              case "response_for":
                                return "Response For"
                              case "credit_decision_vista":
                                return "Credit decision vista"
                              case "partial_name":
                                return "Partial name"
                              case "country_id":
                                return "Country Name"
                              case "default_locale":
                                return "Default Locale"
                              case "customized_result_view":
                                return "Customized Result View"
                              case "customized_view":
                                return "Customized View"
                              case "sms_template_enabled":
                                return "SMS Template Enabled"
                              case "homepage_address":
                                return "Homepage Address"
                              case "language":
                                return "Language"
                              case "phone":
                                return "Phone Number"
                              case "active":
                                return "Active"
                              case "corporate_enabled":
                                return "Corporate Enabled"
                              case "customer_enabled":
                                return "Customer Enabled"
                              case "show_transactions":
                                return "Show Transactions"
                              case "organization_number":
                                return "Organization Number"
                              case "agreement":
                                return "agreement"
                              case "download_request_log_csv":
                                return "Download request log CSV"
                              case "hide_request_log_icons":
                                return "Hide request log icons"
                              case "credit_decision_editable":
                                return "Credit Decision Editable"
                              case "healthcheck_dashboard_enabled":
                                return "Healthcheck Dashboard Enabled"
                              default:
                                return keyName;
                            }
                          }

                          if (adminLog.column_affected.certificate_tabs) {
                            const { certificate_tabs: certificateTabs } = adminLog.column_affected;
                            const certificateTabsBefore = certificateTabs[0];
                            const certificateTabsAfter = certificateTabs[1];
                            const changesBetweenConsumer = (param1, param2) => {
                              const changes =
                                _.differenceWith(_.toPairs(param1), _.toPairs(param2), _.isEqual)
                              return _.fromPairs(changes)
                            }
                            let consumer_tab = changesBetweenConsumer(certificateTabsAfter, certificateTabsBefore)
                            entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Certificate Tabs </strong></p>
                                {
                                  Object.keys(consumer_tab).map(item => <p className='al-column-affected-p' key={Math.random()}>{`${item}:
                                   ${consumer_tab[item] ? "false" : "true"} → ${consumer_tab[item]}`}</p>)
                                }
                              </div>
                            ));
                          }

                          if (key === 'updated_at') {
                            key = adminLog?.column_affected && Object.keys(adminLog?.column_affected)[0];
                          }
                          if (keys === "certificate_type") {
                            let oldValue = adminLog.column_affected.certificate_type[0];
                            let newValue = adminLog.column_affected.certificate_type[1];

                            entityAffectedComponents.push(
                              <div key={Math.random()}>
                                <p className="al-column-affected-p">
                                  <strong>{keys}</strong></p>
                                <p className="al-column-affected-p">
                                  {`${oldValue} → ${newValue} `}
                                </p>
                              </div>
                            );
                          }

                          if (adminLog.column_affected.notification_settings) {
                            let notificationSettings = adminLog.column_affected?.notification_settings;
                            let notificationSettingUpdates = [];
                            let types = [];
                            Object.entries(notificationSettings).forEach((x) => {
                              if (x[0] === "type") {
                                x[1].forEach((y) => {
                                  types.push(y)
                                })
                              }
                              if (x[0] === "notification_updates") {
                                x[1].forEach((y, i) => {
                                  if (Object.entries(y).length !== 0) {
                                    notificationSettingUpdates.push({ notification_updates: y, type: types[i] })
                                  }
                                })
                              }
                            })
                            entityAffectedComponents.push(
                              <div key={Math.random()}>
                                <p className="al-column-affected-p">
                                  <strong>Notification settings</strong>
                                </p>
                                {notificationSettingUpdates.map((x) => (
                                  <span key={Math.random()}>
                                    {Object.entries(x.notification_updates).length > 0 && Object.entries(x.notification_updates).map((y) => (
                                      <p className="al-column-affected-p" key={Math.random()}>{y[0] !== "updated_at" && <>
                                        {`${x.type}: ${y[1][0]} → ${y[1][1]}`}</>}</p>
                                    ))}
                                  </span>
                                ))}
                              </div>
                            );
                          }
                          if (adminLog.column_affected.enable_back_button) {
                            let buttonValuesArray = [];
                            let firstValues = Object.entries(adminLog.column_affected.enable_back_button[0]);
                            let secondValues = Object.entries(adminLog.column_affected.enable_back_button[1]);

                            let firstValuesArr = [];
                            firstValues.forEach((x, y) => {
                              firstValuesArr.push({ name: x[0], value: x[1] })
                            })
                            let secondValuesArr = [];
                            secondValues.forEach((x, y) => {
                              secondValuesArr.push({ name: x[0], value: x[1] })
                            })

                            for (let x = 0; x < firstValuesArr.length; x++) {
                              for (let y = 0; y < secondValuesArr.length; y++) {
                                if ((firstValuesArr[x].name === secondValuesArr[y].name) && (firstValuesArr[x].value !== secondValuesArr[y].value)) {
                                  buttonValuesArray.push({
                                    name: secondValuesArr[y].name,
                                    value1: firstValuesArr[x].value,
                                    value2: secondValuesArr[y].value
                                  })
                                }
                              }
                            }

                            entityAffectedComponents.push(
                              <div
                                key={Math.random()}
                              >

                                <p className="al-column-affected-p">
                                  <strong>Enable Back Button</strong>
                                </p>
                                <p className="al-column-affected-p">
                                  {buttonValuesArray.map((x, y) => (
                                    <span key={y}>  {`${x.name}: ${x.value1} → ${x.value2}`}<br></br></span>
                                  ))}
                                </p>
                              </div>
                            );
                          }

                          if (adminLog.column_affected.service_setting_created) {
                            return (
                              <ServiceSettingCreated adminLog={adminLog} />
                            )
                          }
                          if (adminLog.column_affected.service_setting_updated) {
                            return (
                              <ServiceSettingUpdated adminLog={adminLog} />
                            )
                          }
                          if (adminLog.column_affected.report) {
                            return (
                              <ReportLog adminLog={adminLog} />
                            )
                          }
                          if (adminLog.column_affected.kreditz_provider) {
                            return (
                              <KreditzProviderUpdated adminLog={adminLog} />
                            )
                          }
                          if (adminLog.column_affected.certificate_deletion) {
                            return (
                              <CertificateDeletion adminLog={adminLog} />
                            )
                          }
                          if (adminLog.column_affected.api_access_code) {
                            return (
                              <ApiAccessCode adminLog={adminLog} />
                            )
                          }
                          if (adminLog.column_affected.demo_access_code) {
                            return (
                              <DemoAccessCodeSent adminLog={adminLog} />
                            )
                          }
                          if (adminLog.column_affected.organization_provider_deleted) {
                            return (
                              <OrganizationProviderDeleted adminLog={adminLog} />
                            )
                          }
                          if (adminLog.column_affected.organization_provider_created) {
                            return (
                              <OrganizationProviderCreated adminLog={adminLog} />
                            )
                          }
                          if (adminLog.column_affected.corporate_documents) {
                            const firstValues = Object.keys(adminLog.column_affected.corporate_documents[0]);
                            const secondValues = Object.keys(adminLog.column_affected.corporate_documents[1]);
                            entityAffectedComponents.push(
                              <div key={Math.random()} >
                                <p className="al-column-affected-p">
                                  <strong>Corporate Documents</strong>
                                </p>
                                <p className="al-column-affected-p">
                                  {firstValues.length > 0 ? firstValues + " " : "null"} → {secondValues.length > 0 ? secondValues + " " : "null"}
                                </p>
                              </div>
                            );
                          }
                          if (adminLog.column_affected.validate_keys) {
                            let validateKeys = adminLog.column_affected.validate_keys;
                            let validateKeysValues = {
                              locale: [validateKeys[0].locale, validateKeys[1].locale],
                              redirect_uri: [validateKeys[0].redirect_uri, validateKeys[1].redirect_uri]
                            }
                            entityAffectedComponents.push(
                              <div key={Math.random()} >
                                <p className="al-column-affected-p">
                                  <strong>Validate Keys</strong>
                                </p>
                                {Object.entries(validateKeysValues).map((x) => (x[1][0] !== x[1][1] &&
                                  <p className="al-column-affected-p" key={Math.random()}>
                                    {`${x[0]}: ${x[1][0]} → ${x[1][1]}`}
                                  </p>)
                                )}
                                <p className="al-column-affected-p">
                                </p>
                              </div>
                            );
                          }
                          keys && keys.length > 0 && keys.forEach(item => {
                            if (adminLog.column_affected[item]) {
                              if (item !== 'certificate_tabs' && item !== 'analyzing_markets' && item !== 'corporate_tabs' && item !== 'certificate_type' && item !== 'customer_documents' && item !== 'analyzing_markets' && item !== 'webhook_events' && item !== 'account_type' && item !== 'country_id' && item !== 'column_affected' && item !== "enable_back_button" && item !== "notification_settings" && item !== "report" && item !== "certificate_deletion" && item !== "kreditz_provider" && item !== "api_access_code" && item !== "demo_access_code" && item !== "organization_provider_deleted" && item !== "organization_provider_created" && item !== "service_setting_created" && item !== "service_setting_updated" && item !== "corporate_documents" && item !== "validate_keys") {
                                const stringParts = [];
                                let corporateOverviewBefore =
                                  adminLog?.column_affected && Object.values(adminLog?.column_affected[item])[0];
                                let corporateOverviewAfter = adminLog?.column_affected && Object.values(adminLog?.column_affected[item])[1];
                                if (item === "certificate_delete_ability") {
                                  corporateOverviewBefore = Object.values(corporateOverviewBefore)
                                  corporateOverviewAfter = Object.values(corporateOverviewAfter)
                                }
                                if (
                                  corporateOverviewBefore !== corporateOverviewAfter
                                ) {
                                  stringParts.push(
                                    `${corporateOverviewBefore} → ${corporateOverviewAfter}`
                                  );
                                }
                                const resultString = stringParts.join("\n");
                                entityAffectedComponents.push(
                                  <div
                                    key={Math.random()}
                                  >

                                    <p className="al-column-affected-p">
                                      <strong>{getKeyName(item)}</strong>
                                    </p>
                                    <p className="al-column-affected-p">
                                      {resultString}
                                    </p>
                                  </div>
                                );
                              }
                            }
                          })

                          // CORPORATE TABS
                          if (adminLog.column_affected.corporate_tabs) {
                            const { corporate_tabs: corporateTabs } = adminLog.column_affected;
                            const corporateTabsBefore = corporateTabs[0];
                            const corporateTabsAfter = corporateTabs[1];
                            const changesBetweenCorporate = (param1, param2) => {
                              const changes =
                                _.differenceWith(_.toPairs(param1), _.toPairs(param2), _.isEqual)
                              return _.fromPairs(changes)
                            }
                            let corporate_tab = changesBetweenCorporate(corporateTabsAfter, corporateTabsBefore)
                            entityAffectedComponents.push(
                              <div
                                key={Math.random()}
                              >
                                <p className="al-column-affected-p">
                                  <strong>Corporate Tabs</strong>
                                </p>
                                {
                                  Object.keys(corporate_tab).map(item => <p className='al-column-affected-p' key={Math.random()}>{`${item}:
                                   ${corporate_tab[item] ? "false" : "true"} → ${corporate_tab[item]}`}</p>)
                                }
                              </div>
                            )
                          }
                          // CERTIFICATE MODULIZATION
                          if (adminLog.column_affected.certificate_type === "consumer") {
                            const { account_information, income_tab, spending_tab, asset_tab, debts_tab, credit_decision_tab, source_tab, overview } = adminLog.column_affected.column_affected;
                            const changesBetweenConsumer = (param1, param2) => {
                              const changes =
                                _.differenceWith(_.toPairs(param1), _.toPairs(param2), _.isEqual)
                              return _.fromPairs(changes)
                            }
                            const accountinformationBefore = account_information?.[0];
                            const account_informationAfter = account_information?.[1];
                            changesBetweenConsumer(account_informationAfter, accountinformationBefore)
                            const incomeTabBefore = income_tab?.[0];
                            const incomeTabAfter = income_tab?.[1];
                            let income_tab_diff = changesBetweenConsumer(incomeTabAfter, incomeTabBefore)
                            const spendingTabBefore = spending_tab?.[0];
                            const spendingTabAfter = spending_tab?.[1];
                            let spending_tab_diff = changesBetweenConsumer(spendingTabAfter, spendingTabBefore)
                            const assetTabBefore = asset_tab?.[0];
                            const assetTabAfter = asset_tab?.[1];
                            let assets_tab_diff = changesBetweenConsumer(assetTabAfter, assetTabBefore)
                            const debtsTabBefore = debts_tab?.[0];
                            const debtsTabAfter = debts_tab?.[1];
                            let debts_tab_diff = changesBetweenConsumer(debtsTabAfter, debtsTabBefore)
                            const creditDecisionTabBefore = credit_decision_tab?.[0];
                            const creditDecisionTabAfter = credit_decision_tab?.[1];
                            let credit_decision_tab_diff = changesBetweenConsumer(creditDecisionTabAfter, creditDecisionTabBefore);
                            let overviewValues = {
                              assets: [overview?.[0].assets ? overview?.[0].assets : false, overview?.[1].assets],
                              business_rules: [overview?.[0].business_rules ? overview?.[0].business_rules : false, overview?.[1].business_rules],
                              debts: [overview?.[0].debts ? overview?.[0].debts : false, overview?.[1].debts],
                              primary_income: [overview?.[0].primary_income ? overview?.[0].primary_income : false, overview?.[1].primary_income],
                              sources: [overview?.[0].sources ? overview?.[0].sources : false, overview?.[1].sources],
                              spending: [overview?.[0].spending ? overview?.[0].spending : false, overview?.[1].spending]
                            };
                            entityAffectedComponents.push((
                              <p className='al-column-affected-p'><strong>Consumer Certificate Modulization</strong></p>
                            ))
                            account_information && entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Account Information</strong></p>
                                <p className='al-column-affected-p'>Balance Column: {`${getValue(accountinformationBefore?.balance_column)} → ${account_informationAfter?.balance_column}`}</p>
                                <p className='al-column-affected-p'>Transaction Column: {`${getValue(accountinformationBefore?.transaction_column)} → ${account_informationAfter?.transaction_column}`}</p>
                              </div>
                            ))

                            source_tab && entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Source Tab</strong></p>
                                <p className='al-column-affected-p'> Bank sources - Box :{`${getValue(source_tab[0])} → ${source_tab[1]}`}</p>
                              </div>
                            ))

                            overview && entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Overview</strong></p>
                                {Object.entries(overviewValues).map((x) => (
                                  x[1][0] !== x[1][1] && (
                                    <div key={Math.random()}>
                                      <p className='al-column-affected-p'>{`${x[0]}: ${x[1][0]} → ${x[1][1]}`}</p>
                                    </div>
                                  )
                                )
                                )}
                              </div>
                            ))
                            income_tab && entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Income Tab</strong></p>
                                {
                                  Object.keys(income_tab_diff).map(item => <p className='al-column-affected-p' key={Math.random()}>{`${item}:
                                  ${income_tab_diff[item] ? "false" : "true"} → ${income_tab_diff[item]}`}</p>)
                                }
                              </div>
                            ))

                            spending_tab && entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Spending Tab</strong></p>
                                {
                                  Object.keys(spending_tab_diff).map(item => <p className='al-column-affected-p' key={Math.random()}>{`${item}:
                                  ${spending_tab_diff[item] ? "false" : "true"} → ${spending_tab_diff[item]}`}</p>)
                                }
                              </div>
                            ))

                            asset_tab && entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Assets Tab</strong></p>
                                {
                                  Object.keys(assets_tab_diff).map(item => <p className='al-column-affected-p' key={Math.random()}>{`${item}:
                                  ${assets_tab_diff[item] ? "false" : "true"} → ${assets_tab_diff[item]}`}</p>)
                                }
                              </div>
                            ))

                            debts_tab && entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Debts Tab</strong></p>
                                {
                                  Object.keys(debts_tab_diff).map(item => <p className='al-column-affected-p' key={Math.random()}>{`${item}:
                                  ${debts_tab_diff[item] ? "false" : "true"} → ${debts_tab_diff[item]}`}</p>)
                                }
                              </div>
                            ))

                            credit_decision_tab && entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Credit Decision Tab</strong></p>
                                {
                                  Object.keys(credit_decision_tab_diff).map(item => <p className='al-column-affected-p' key={Math.random()}>{`${item}:
                                  ${credit_decision_tab_diff[item] ? "false" : "true"} → ${credit_decision_tab_diff[item]}`}</p>)
                                }
                              </div>
                            ))
                          }

                          if (adminLog.column_affected.certificate_type === "corporate") {
                            const { credit_decision_tab, bussiness_rule_tab, cashflow_tab, source_tab, overview } = adminLog.column_affected.column_affected;
                            const changesBetweenCorporate = (param1, param2) => {
                              const changes =
                                _.differenceWith(_.toPairs(param1), _.toPairs(param2), _.isEqual)
                              return _.fromPairs(changes)
                            }
                            const sourcetabBefore = source_tab?.[0];
                            const sourcetabAfter = source_tab?.[1];
                            changesBetweenCorporate(sourcetabAfter, sourcetabBefore)
                            const creditDecisionTabBefore = credit_decision_tab?.[0];
                            const creditDecisionTabAfter = credit_decision_tab?.[1];
                            let credit_decision_tab_diff = changesBetweenCorporate(creditDecisionTabAfter, creditDecisionTabBefore)
                            const bussinessRuleTabBefore = bussiness_rule_tab?.[0];
                            const bussinessRuleTabAfter = bussiness_rule_tab?.[1];
                            changesBetweenCorporate(bussinessRuleTabAfter, bussinessRuleTabBefore)
                            const cashflowTabBefore = cashflow_tab?.[0];
                            const cashflowTabAfter = cashflow_tab?.[1];
                            let cashflow_tab_diff = changesBetweenCorporate(cashflowTabAfter, cashflowTabBefore)
                            let overviewValues = {
                              cash_and_bank_balances: [overview?.[0].cash_and_bank_balances ? overview?.[0].cash_and_bank_balances : false, overview?.[1].cash_and_bank_balances],
                              cashflow: [overview?.[0].cashflow ? overview?.[0].cashflow : false, overview?.[1].cashflow],
                              largest_incoming_payments: [overview?.[0].largest_incoming_payments ? overview?.[0].largest_incoming_payments : false, overview?.[1].largest_incoming_payments],
                              largest_outgoing_payments: [overview?.[0].largest_outgoing_payments ? overview?.[0].largest_outgoing_payments : false, overview?.[1].largest_outgoing_payments],
                              source_box: [overview?.[0].source_box ? overview?.[0].source_box : false, overview?.[1].source_box],
                              sources: [overview?.[0].sources ? overview?.[0].sources : false, overview?.[1].sources],
                              tax_authority: [overview?.[0].tax_authority ? overview?.[0].tax_authority : false, overview?.[1].tax_authority]
                            };
                            entityAffectedComponents.push((
                              <p className='al-column-affected-p'><strong>Corporate Certificate Modulization</strong></p>
                            ))

                            source_tab && entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Source Tab</strong></p>
                                {getValue(sourcetabBefore["bank_sources_box"]) !== sourcetabAfter["bank_sources_box"] &&
                                  <p className='al-column-affected-p'>Bank Sources Box: {`${getValue(sourcetabBefore["bank_sources_box"])} → ${sourcetabAfter["bank_sources_box"]}`}</p>}
                                {getValue(sourcetabBefore["bank_accounts"]) !== sourcetabAfter["bank_accounts"] &&
                                  <p className='al-column-affected-p'>Bank Accounts: {`${getValue(sourcetabBefore["bank_accounts"])} → ${sourcetabAfter["bank_accounts"]}`}</p>}
                              </div>
                            ))

                            overview && entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Overview</strong></p>
                                {Object.entries(overviewValues).map((x) => (
                                  x[1][0] !== x[1][1] && (
                                    <div key={Math.random()}>
                                      <p className='al-column-affected-p'>{`${x[0]}: ${x[1][0]} → ${x[1][1]}`}</p>
                                    </div>
                                  )
                                )
                                )}
                              </div>
                            ))

                            credit_decision_tab && entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Credit Decision Tab</strong></p>
                                {
                                  Object.keys(credit_decision_tab_diff).map(item => <p className='al-column-affected-p' key={Math.random()}>{`${item}:
                                  ${credit_decision_tab_diff[item] ? "false" : "true"} → ${credit_decision_tab_diff[item]}`}</p>)
                                }
                              </div>
                            ))

                            bussiness_rule_tab && entityAffectedComponents.push((
                              <div key={`admin-logs-business-rule-tabs-${index + 1} `}>
                                <p className='al-column-affected-p'><strong>Bussiness Rule Tab</strong></p>
                                <p className='al-column-affected-p'>Business rules - Section: {`${getValue(bussinessRuleTabBefore["bussiness_rules_section"])} → ${bussinessRuleTabAfter["bussiness_rules_section"]}`}</p>
                              </div>
                            ))

                            cashflow_tab && entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Cashflow Tab</strong></p>
                                {
                                  Object.keys(cashflow_tab_diff).map(item => <p className='al-column-affected-p' key={Math.random()}>{`${item}:
                                  ${cashflow_tab_diff[item] ? "false" : "true"} → ${cashflow_tab_diff[item]}`}</p>)
                                }
                              </div>
                            ))
                          }

                          if (adminLog.column_affected.webhook_events) {
                            const { webhook_events } = adminLog.column_affected;
                            const changesBetweenweebhokevent = (param1, param2) => {
                              const changes =
                                _.differenceWith(_.toPairs(param1), _.toPairs(param2), _.isEqual)
                              return _.fromPairs(changes)
                            }
                            const webhook_eventsBefore = webhook_events[0];
                            const webhook_eventsAfter = webhook_events[1];
                            let webhook_events_diff = changesBetweenweebhokevent(webhook_eventsAfter, webhook_eventsBefore)
                            entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <strong>webhook events</strong>
                                {
                                  Object.keys(webhook_events_diff).map(item => <p className='al-column-affected-p' key={Math.random()}>{`${item}:
                                  ${webhook_events_diff[item] ? "false" : "true"} → ${webhook_events_diff[item]}`}</p>)
                                }
                              </div>
                            ));
                          }

                          if (adminLog.column_affected.analyzing_markets) {
                            const { analyzing_markets: analyzingMarkets } =
                              adminLog.column_affected;
                            const stringParts = [];
                            var falseMarket = [];
                            var trueMarket = [];
                            const analyzingMarketsBefore =
                              analyzingMarkets[0] || [];
                            const analyzingMarketsAfter =
                              analyzingMarkets[1] || [];
                            if (
                              analyzingMarketsBefore !== analyzingMarketsAfter
                            ) {
                              stringParts.push(
                                `${analyzingMarketsBefore} → ${analyzingMarketsAfter}`
                              );
                            }
                            if (
                              analyzingMarketsAfter.length > 0 &&
                              analyzingMarketsBefore.length > 0
                            ) {
                              trueMarket = analyzingMarketsAfter.filter(
                                (val) => !analyzingMarketsBefore.includes(val)
                              );
                            } else if (
                              analyzingMarketsAfter.length > 0 &&
                              analyzingMarketsBefore.length === 0
                            ) {
                              trueMarket = analyzingMarketsAfter;
                            }
                            if (
                              analyzingMarketsBefore.length > 0 &&
                              analyzingMarketsAfter.length > 0
                            ) {
                              falseMarket = analyzingMarketsBefore.filter(
                                (val) => !analyzingMarketsAfter.includes(val)
                              );
                            } else if (
                              analyzingMarketsBefore.length > 0 &&
                              analyzingMarketsAfter.length === 0
                            ) {
                              falseMarket = analyzingMarketsBefore;
                            }
                            if (falseMarket.length > 0) {
                              entityAffectedComponents.push(
                                <div
                                  key={Math.random()}
                                >
                                  <p className="al-column-affected-p">
                                    <strong>Analyzing Markets</strong>
                                  </p>
                                  {falseMarket.map((country_id) => (
                                    <p className="al-column-affected-p" key={Math.random()}>
                                      {`${COUNTRIES.find(
                                        (country) =>
                                          country.id === parseInt(country_id)
                                      )?.name
                                        } → False`}
                                    </p>
                                  ))}
                                </div>
                              );
                            }
                            if (trueMarket.length > 0) {
                              entityAffectedComponents.push(
                                <div
                                  key={Math.random()}
                                >
                                  <p className="al-column-affected-p">
                                    <strong>Analyzing Markets</strong>
                                  </p>
                                  {trueMarket.map((country_id) => (
                                    <p className="al-column-affected-p" key={Math.random()}>
                                      {`${COUNTRIES.find(
                                        (country) =>
                                          country.id === parseInt(country_id)
                                      )?.name
                                        } → True`}
                                    </p>
                                  ))}
                                </div>
                              );
                            }
                          }


                          if (adminLog.column_affected.income_tab) {
                            const { income_tab: incomeTab } = adminLog.column_affected;
                            const stringParts = [];
                            const incomeTabBefore = incomeTab[0];
                            const incomeTabAfter = incomeTab[1];
                            if (incomeTabBefore !== incomeTabAfter) {
                              stringParts.push(`${incomeTabBefore} → ${incomeTabAfter}`)
                            }
                            const resultString = stringParts.join('\n');
                            entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'>Income-tab: {resultString}</p>
                              </div>
                            ));
                          }

                          if (adminLog.column_affected.debts) {
                            const debts = adminLog.column_affected;
                            const stringParts = [];
                            const debtsBefore = debts[0];
                            const debtsAfter = debts[1];
                            if (debtsBefore !== debtsAfter) {
                              stringParts.push(`${debtsBefore} → ${debtsAfter}`)
                            }
                            const resultString = stringParts.join('\n');
                            entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'>Debts: {resultString}</p>
                              </div>
                            ));
                          }

                          if (adminLog.column_affected.expense_tab) {
                            const expense_tab = adminLog.column_affected;
                            const stringParts = [];
                            const expense_tabBefore = expense_tab[0];
                            const expense_tabAfter = expense_tab[1];
                            if (expense_tabBefore !== expense_tabAfter) {
                              stringParts.push(`${expense_tabBefore} → ${expense_tabAfter}`)
                            }
                            const resultString = stringParts.join('\n');
                            entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'>Expense-tab: {resultString}</p>
                              </div>
                            ));
                          }

                          if (adminLog.column_affected.assets) {
                            const assets = adminLog.column_affected;
                            const stringParts = [];
                            const assetsBefore = assets[0];
                            const assetsAfter = assets[1];
                            if (assetsBefore !== assetsAfter) {
                              stringParts.push(`${assetsBefore} → ${assetsAfter}`)
                            }
                            const resultString = stringParts.join('\n');
                            entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'>Assets: {resultString}</p>
                              </div>
                            ));
                          }

                          if (adminLog.column_affected.corporate_cashflow) {
                            const corporate_cashflow = adminLog.column_affected;
                            const stringParts = [];
                            const corporate_cashflowBefore = corporate_cashflow[0];
                            const corporate_cashflowAfter = corporate_cashflow[1];
                            if (corporate_cashflowBefore !== corporate_cashflowAfter) {
                              stringParts.push(`${corporate_cashflowBefore} → ${corporate_cashflowAfter}`)
                            }
                            const resultString = stringParts.join('\n');
                            entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'>corporate_cashflow: {resultString}</p>
                              </div>
                            ));
                          }

                          if (adminLog.column_affected.account_type) {
                            const account_type = adminLog.column_affected;
                            const stringParts = [];
                            const account_typeBefore = account_type[0];
                            const account_typeAfter = account_type[1];
                            if (account_typeBefore !== account_typeAfter) {
                              stringParts.push(`${account_typeBefore} → ${account_typeAfter}`)
                            }
                            const resultString = stringParts.join('\n');
                            entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <strong>account-type</strong>
                                <p className='al-column-affected-p'>{resultString}</p>
                              </div>
                            ));
                          }

                          if (adminLog.column_affected.market_ids) {
                            const { market_ids: marketIDs } =
                              adminLog.column_affected;
                            const beforeMarket = [];
                            const afterMarket = [];
                            const marketIDsBefore = marketIDs[0];
                            const marketIDsAfter = marketIDs[1];

                            marketIDsBefore.map((country_id) =>
                              beforeMarket.push(
                                COUNTRIES.find(
                                  (country) =>
                                    country.id === parseInt(country_id)
                                )?.name
                              )
                            );
                            marketIDsAfter.map((country_id) =>
                              afterMarket.push(
                                COUNTRIES.find(
                                  (country) =>
                                    country.id === parseInt(country_id)
                                )?.name
                              )
                            );
                            entityAffectedComponents.push(
                              <div key={Math.random()}>
                                <p className="al-column-affected-p">
                                  <strong>Consumer Markets</strong>
                                </p>
                                <p className="al-column-affected-p">
                                  {`${JSON.stringify(
                                    beforeMarket.filter((x) => x !== undefined)
                                  )} → ${JSON.stringify(
                                    afterMarket.filter((x) => x !== undefined)
                                  )}`}
                                </p>
                              </div>
                            );
                          }
                          if (adminLog.column_affected.customer_documents) {
                            const firstValues = Object.keys(adminLog.column_affected.customer_documents[0]);
                            const secondValues = Object.keys(adminLog.column_affected.customer_documents[1]);
                            entityAffectedComponents.push(
                              <div key={Math.random()} >
                                <p className="al-column-affected-p">
                                  <strong>Customer Documents</strong>
                                </p>
                                <p className="al-column-affected-p">
                                  {firstValues.length > 0 ? firstValues + " " : "null"} → {secondValues.length > 0 ? secondValues + " " : "null"}
                                </p>
                              </div>
                            );
                          }
                          if (adminLog.column_affected.corporate_market_ids) {
                            const { corporate_market_ids: cmarketIDs } =
                              adminLog.column_affected;
                            const corpBeforeMarket = [];
                            const corpAfterMarket = [];
                            const corpMarketIDsBefore = cmarketIDs[0];
                            const corpMarketIDsAfter = cmarketIDs[1];

                            corpMarketIDsBefore.map((country_id) =>
                              corpBeforeMarket.push(
                                COUNTRIES.find(
                                  (country) =>
                                    country.id === parseInt(country_id)
                                )?.name
                              )
                            );
                            corpMarketIDsAfter.map((country_id) =>
                              corpAfterMarket.push(
                                COUNTRIES.find(
                                  (country) =>
                                    country.id === parseInt(country_id)
                                )?.name
                              )
                            );
                            entityAffectedComponents.push(
                              <div key={Math.random()}>
                                <p className="al-column-affected-p">
                                  <strong>Corporate Markets</strong>
                                </p>
                                <p className="al-column-affected-p">
                                  {`${JSON.stringify(
                                    corpBeforeMarket.filter((x) => x !== undefined)
                                  )} → ${JSON.stringify(
                                    corpAfterMarket.filter((x) => x !== undefined)
                                  )}`}
                                </p>
                              </div>
                            );
                          }

                          if (adminLog.column_affected.country_id) {
                            const { country_id: countryId } = adminLog.column_affected;
                            const stringParts = [];
                            countryId.map(country_id => (stringParts.push(COUNTRIES.find(country => country.id === parseInt(country_id))?.name)))
                            entityAffectedComponents.push((
                              <div key={Math.random()}>
                                <p className='al-column-affected-p'><strong>Country Name</strong></p>
                                <p className='al-column-affected-p'>
                                  {`${stringParts[0]} → ${stringParts[1]}`}
                                </p>
                              </div>
                            ));
                          }

                          if (
                            !adminLog.column_affected &&
                            adminLog.object_id &&
                            adminLog.organization_name
                          ) {
                            return (
                              <div
                                key={Math.random()}
                              >
                                <p className="al-column-affected-p">
                                  <strong>ID: </strong>
                                  {adminLog.object_id}
                                </p>
                                <p className="al-column-affected-p">
                                  <strong>Name: </strong>
                                  {adminLog.organization_name}
                                </p>
                              </div>
                            );
                          }

                          if (!adminLog.column_affected) {
                            return (
                              <div
                                key={Math.random()}
                              >
                                <p className="al-column-affected-p">—</p>
                              </div>
                            );
                          }

                          if (
                            Object.keys(adminLog.column_affected).length === 0
                          ) {
                            return (
                              <div key={Math.random()}>
                                <p className="al-column-affected-p">—</p>
                              </div>
                            );
                          }

                          return entityAffectedComponents;
                        })()}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="pagination-wrapper">
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              breakLabel={"..."}
              pageCount={total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={selectPageHandler}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </div >
  );
};

export default AdminLogsList;
