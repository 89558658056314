import React, { useState, useEffect } from 'react';
import ToggleOrgCertificate from '../ToggleOrgCertificate/ToggleOrgCertificate';
import MarketTab from '../MarketTab/MarketTab';
import AnalyzingTab from '../AnalyzingTab/Analyzing';

function CertificateTab({
  setClientDetails,
  clientDetails,
  capitalizeFirstLetter,
  submitHandle,
  bankFilterCountries,
  setBankFilterCountries,
  setBankFilters,
  bankFilterCountriesCorporate,
  setBankFilterCountriesCorporate,
  country,
  handleSetting
}) {
  const [incomeTabAll, setIncomeTabAll] = useState(false)
  const [creditDecisionTabAll, setCreditDecisionTabAll] = useState(false)
  const [spendingTabAll, setSpendingTabAll] = useState(false)
  const [assetsTabAll, setAssetsTabAll] = useState(false)
  const [debetsTabAll, setDebtsTabAll] = useState(false)
  const [cashflowTabAll, setCashflowTabAll] = useState(false)
  const [creditDecisionTabAllCorporate, setCreditDecisionTabAllCorporate] = useState(false)
  const [growthTabAll, setGrowthTabAll] = useState(false)
  const [profitabilityTabAll, setProfitabilityTabAll] = useState(false)
  const [invoicesTabAll, setInvoicesTabAll] = useState(false)
  const [balanceSheetTabAll, setBalanceSheetTabAll] = useState(false)

  useEffect(() => {

    const checkAllSelectedTabValueConsumer = (keyObj) => {
      const consumerTabAllValues = Object.values(clientDetails.certificate.consumer[keyObj]);
      return consumerTabAllValues.every(Boolean);
    }

    const checkAllSelectedTabValueCorporate = (keyObj) => {
      const corporateTabAllValues = Object.values(clientDetails.certificate.corporate[keyObj]);
      return corporateTabAllValues.every(Boolean);
    }
    setIncomeTabAll(checkAllSelectedTabValueConsumer('income_tab'));
    setCreditDecisionTabAll(checkAllSelectedTabValueConsumer('credit_decision_tab'));
    setSpendingTabAll(checkAllSelectedTabValueConsumer('spending_tab'));
    setAssetsTabAll(checkAllSelectedTabValueConsumer('asset_tab'));
    setDebtsTabAll(checkAllSelectedTabValueConsumer('debts_tab'));

    setCashflowTabAll(checkAllSelectedTabValueCorporate('cashflow_tab'));
    setCreditDecisionTabAllCorporate(checkAllSelectedTabValueCorporate('credit_decision_tab'));
    setGrowthTabAll(checkAllSelectedTabValueCorporate('growth'));
    setProfitabilityTabAll(checkAllSelectedTabValueCorporate('profitability'));
    setInvoicesTabAll(checkAllSelectedTabValueCorporate('invoices'));
    setBalanceSheetTabAll(checkAllSelectedTabValueCorporate('balance_sheet'));
  }, [clientDetails])


  const consumerCertificateHandler = (event, type, key) => {

    const enableAllConsumerTabs = (keyObj) => {
      setClientDetails(prevState => {
        const updatedIncomeTab = Object.keys(prevState?.certificate?.consumer?.[keyObj] || {}).reduce((acc, key) => {
          acc[key] = event.target.checked;
          return acc;
        }, {});

        return {
          ...prevState,
          certificate: {
            ...prevState?.certificate,
            consumer: {
              ...prevState?.certificate?.consumer,
              [keyObj]: {
                ...prevState?.certificate?.consumer?.[keyObj],
                ...updatedIncomeTab,
              },
            },
          },
        };
      });
    }

    if (type === 'bank_sources_box') {
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          consumer: {
            ...prevState?.certificate?.consumer,
            bank_sources_box: event.target.checked
          },
        }
      }))
    }

    setClientDetails(prevState => ({
      ...prevState,
      certificate: {
        ...prevState?.certificate,
        consumer: {
          ...prevState?.certificate?.consumer,
          [key]: {
            ...prevState?.certificate?.consumer[key],
            [type]: event.target.checked
          }
        },
      }
    }))



    if (type === 'income_tab_all') {
      setIncomeTabAll(event.target.checked)
      enableAllConsumerTabs('income_tab')
    }

    if (type === "spending_tab_all") {
      setSpendingTabAll(event.target.checked)
      enableAllConsumerTabs('spending_tab')
    }

    if (type === 'spending_monthly_view') {
      setSpendingTabAll(false)
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          consumer: {
            ...prevState?.certificate?.consumer,
            spending_tab: {
              ...prevState?.certificate?.consumer?.spending_tab,
              monthly_view: event.target.checked
            }
          },
        }
      }))
    }

    if (type === "assets_tab_all") {
      setAssetsTabAll(event.target.checked)
      enableAllConsumerTabs('asset_tab')
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          consumer: {
            ...prevState?.certificate?.consumer,
            asset_tab: {
              assets_graph: event.target.checked,
              balance_before_salary_graph: event.target.checked,
              monthly_savings: event.target.checked,
              total_assets: event.target.checked,
              asset_ratio: event.target.checked,
              asset_allocation: event.target.checked,
              monthly_savings_ratio: event.target.checked,
            },
          }
        }
      }))
      enableAllConsumerTabs('asset_tab')
    }


    if (type === "debts_tab_all") {
      setDebtsTabAll(event.target.checked)
      enableAllConsumerTabs('debts_tab')
    }


    if (type === 'bussiness_rules_section') {
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          consumer: {
            ...prevState?.certificate?.consumer,
            debts_tab: {
              ...prevState?.certificate?.consumer?.debts_tab,
              bussiness_rules_section: event.target.checked
            }
          }
        }
      }))
    }

    if (type === "credit_decision_tab_all") {
      setCreditDecisionTabAll(event.target.checked)
      enableAllConsumerTabs('credit_decision_tab')
    }
  }

  const corporateCertificatesHandler = (event, type, key) => {

    const enableAllCorporateTabs = (keyObj) => {
      setClientDetails(prevState => {
        const updatedIncomeTab = Object.keys(prevState?.certificate?.corporate?.[keyObj] || {}).reduce((acc, key) => {
          acc[key] = event.target.checked;
          return acc;
        }, {});

        return {
          ...prevState,
          certificate: {
            ...prevState?.certificate,
            corporate: {
              ...prevState?.certificate?.corporate,
              [keyObj]: {
                ...prevState?.certificate?.corporate?.[keyObj],
                ...updatedIncomeTab,
              },
            },
          },
        };
      });
    }

    if (type === 'bank_sources_box_corporate') {
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          corporate: {
            ...prevState?.certificate?.corporate,
            source_tab: {
              ...prevState?.certificate?.corporate?.source_tab,
              bank_sources_box: event.target.checked
            }
          },
        }
      }))
    }

    if (type === 'bank_accounts') {
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          corporate: {
            ...prevState?.certificate?.corporate,
            source_tab: {
              ...prevState?.certificate?.corporate?.source_tab,
              bank_accounts: event.target.checked
            }
          },
        }
      }))
    }

    if (type === 'account_information') {
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          corporate: {
            ...prevState?.certificate?.corporate,
            source_tab: {
              ...prevState?.certificate?.corporate?.source_tab,
              account_information: event.target.checked
            }
          },
        }
      }))
    }

    if (type === 'erp_system') {
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          corporate: {
            ...prevState?.certificate?.corporate,
            source_tab: {
              ...prevState?.certificate?.corporate?.source_tab,
              erp_system: event.target.checked
            }
          },
        }
      }))
    }

    if (type === 'cashflow_tab_all') {
      setCashflowTabAll(event.target.checked)
      enableAllCorporateTabs('cashflow_tab')
    }

    setClientDetails(prevState => ({
      ...prevState,
      certificate: {
        ...prevState?.certificate,
        corporate: {
          ...prevState?.certificate?.corporate,
          [key]: {
            ...prevState?.certificate?.corporate[key],
            [type]: event.target.checked
          }
        },
      }
    }))

    if (type === 'credit_decision_corprate_tab_all') {
      setCreditDecisionTabAllCorporate(event.target.checked)
      enableAllCorporateTabs('credit_decision_tab')
    }

    if (type === 'growth_tab_all') {
      setGrowthTabAll(event.target.checked)
      enableAllCorporateTabs('growth')
    }

    if (type === 'profitability_tab_all') {
      setProfitabilityTabAll(event.target.checked)
      enableAllCorporateTabs('profitability')
    }

    if (type === 'invoices_tab_all') {
      setInvoicesTabAll(event.target.checked)
      enableAllCorporateTabs('invoices')
    }

    if (type === 'balance_sheet_tab_all') {
      setBalanceSheetTabAll(event.target.checked)
      enableAllCorporateTabs('balance_sheet')
    }

    if (type === 'credit_policy_rules_box_corporate') {
      setCreditDecisionTabAllCorporate(false)
      setClientDetails(prevState => ({
        ...prevState,
        certificate: {
          ...prevState?.certificate,
          corporate: {
            ...prevState?.certificate?.corporate,
            credit_decision_tab: {
              ...prevState?.certificate?.corporate?.credit_decision_tab,
              credit_policy_rules_box: event.target.checked
            }
          },
        }
      }))
    }
  }
  return (
    <div id="inside_tab_panel">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active" id="consumercertificate-tab" data-bs-toggle="tab" data-bs-target="#consumercertificate" type="button" role="tab" aria-controls="consumercertificate" aria-selected="true">Consumer Certificate</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="corporatecertificate-tab" data-bs-toggle="tab" data-bs-target="#corporatecertificate" type="button" role="tab" aria-controls="corporatecertificate" aria-selected="false">Corporate Certificate</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="market-tab" data-bs-toggle="tab" data-bs-target="#markets_details" type="button" role="tab" aria-controls="markets_details" aria-selected="false">Markets</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="analyzing-tab" data-bs-toggle="tab" data-bs-target="#analyzing_details" type="button" role="tab" aria-controls="analyzing_details" aria-selected="false">Analyzing</button>
        </li>
      </ul>
      <div className="tab-content" id="tab_content_certificate">
        <AnalyzingTab
          setClientDetails={setClientDetails}
          country={country}
          clientDetails={clientDetails}
          handleSetting={handleSetting}
          submitHandle={submitHandle}
        />
        <MarketTab
          setClientDetails={setClientDetails}
          bankFilterCountries={bankFilterCountries}
          setBankFilterCountries={setBankFilterCountries}
          setBankFilters={setBankFilters}
          bankFilterCountriesCorporate={bankFilterCountriesCorporate}
          setBankFilterCountriesCorporate={setBankFilterCountriesCorporate}
          country={country}
          clientDetails={clientDetails}
          submitHandle={submitHandle}
        />
        <div className="tab-pane fade show active" id="consumercertificate" role="tabpanel" aria-labelledby="consumercertificate-tab">
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Overview</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                {
                  ['sources', 'business_rules', 'primary_income', 'spending', 'assets', 'debts'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.overview[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"overview"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          <div className="detailed-box">
            <h3 className="headeing-switch">Account information</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                {
                  ['balance_column', 'transaction_column'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.account_information[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"account_information"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Income tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                {/*===*/}
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={incomeTabAll} onChange={(e) => consumerCertificateHandler
                      (e, 'income_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {/*===*/}
                {
                  ['average_income', 'income_graph', 'monthly_view', 'source_of_income', 'primary_income_transactions', 'income_transactions', 'income_vs_spending', 'cashflow_net_graph', 'income_ratio', 'salary_burn_rate', 'category_expansion'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.income_tab[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"income_tab"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
                {/*===*/}
              </div>
            </div>
          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Spending tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={spendingTabAll} onChange={(e) => consumerCertificateHandler
                      (e, 'spending_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {
                  ['spending_categories_risk', 'spending_categories_loans', 'spending_categories_savings', 'spending_categories_temporary_expenses', 'spending_categories_housing_costs', 'spending_categories_transportation', 'spending_categories_food_and_drinks', 'spending_categories_shopping', 'spending_categories_hobbies', 'spending_categories_health_and_beauty', 'spending_categories_children', 'spending_categories_transfers', 'spending_graph', 'category_expansion', 'highest_cost_last_month', 'spending_ratio'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.spending_tab[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"spending_tab"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.consumer?.spending_tab?.monthly_view} onChange={(e) => consumerCertificateHandler
                      (e, 'spending_monthly_view')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Monthly view - Accordion</p>
                </div>
              </div>
            </div>
          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Assets tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={assetsTabAll} onChange={(e) => consumerCertificateHandler(e, 'assets_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {
                  ['total_assets', 'asset_allocation', 'assets_graph', 'asset_ratio', 'monthly_savings_ratio', 'monthly_savings', 'balance_before_salary_graph',].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.asset_tab[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"asset_tab"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Debts tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={debetsTabAll} onChange={(e) => consumerCertificateHandler
                      (e, 'debts_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {
                  ['summary_of_debts', 'debts_categories_boxes', 'debt_involvement', 'credit_institutions', 'disposition_of_repayments', 'noteworthy_liabilities', 'paidout_vs_repayments_detailed', 'paidout_vs_repayments_accumulated'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.debts_tab[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"debts_tab"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          {/*===*/}
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Credit decision - tab - Application/Surveillance</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={creditDecisionTabAll} onChange={(e) => consumerCertificateHandler
                      (e, 'credit_decision_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {
                  ['overview_decision_box', 'credit_policy_rules_box', 'credit_scoring_box', 'affordability_box', 'category_expansion', 'overview'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.consumer?.credit_decision_tab[keys]} toggleFunction={consumerCertificateHandler} certificateKey={"credit_decision_tab"}
                    capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="corporatecertificate" role="tabpanel" aria-labelledby="corporatecertificate-tab">
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Overview</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                {
                  ['cash_and_bank_balances', 'cashflow', 'largest_incoming_payments', 'largest_outgoing_payments', 'sources', 'tax_authority', 'revenue_chart', 'balance_sheet_chart', 'earnings_chart', 'growth', 'balance_sheet', 'profitability'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.overview[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"overview"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          <div className="detailed-box">
            <h3 className="headeing-switch">Account information</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                {
                  ['balance_column', 'transaction_column'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.account_information[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"account_information"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          <div className="detailed-box">
            <h3 className="headeing-switch">Source tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.corporate?.source_tab?.bank_sources_box} onChange={(e) => corporateCertificatesHandler(e, 'bank_sources_box_corporate')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Bank sources - Box</p>
                </div>
              </div>
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.corporate?.source_tab?.bank_accounts} onChange={(e) => corporateCertificatesHandler(e, 'bank_accounts')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Bank Accounts</p>
                </div>
              </div>
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.corporate?.source_tab?.account_information} onChange={(e) => corporateCertificatesHandler(e, 'account_information')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Account Information</p>
                </div>
              </div>
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.corporate?.source_tab?.erp_system} onChange={(e) => corporateCertificatesHandler(e, 'erp_system')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Erp System</p>
                </div>
              </div>
            </div>

          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Cashflow tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={cashflowTabAll} onChange={(e) => corporateCertificatesHandler(e, 'cashflow_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {/*===*/}
                {
                  ['average_inflow_boxes', 'average_outflow_boxes', 'cashflow_12_months_graph_or_accordion', 'category_expansion', 'account_balance', 'booked_cashflow', 'bank_cashflow_chart', 'bank_cashflow_accordion'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.cashflow_tab[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"cashflow_tab"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
                {/*===*/}
              </div>
            </div>
          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Business rules tab</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.corporate?.bussiness_rule_tab?.bussiness_rules_section} onChange={(e) => corporateCertificatesHandler(e, 'bussiness_rules_section', 'bussiness_rule_tab')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Business rules - Section</p>
                </div>
              </div>
            </div>
          </div>
          {/*===*/}
          <div className="detailed-box">
            <h3 className="headeing-switch">Credit decision</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={creditDecisionTabAllCorporate} onChange={(e) => corporateCertificatesHandler(e, 'credit_decision_corprate_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.corporate?.credit_decision_tab?.overview_decision_box} onChange={(e) => corporateCertificatesHandler(e, 'overview_decision_box', 'credit_decision_tab')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Overview of decision - Box</p>
                </div>
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={clientDetails?.certificate?.corporate?.credit_decision_tab?.credit_policy_rules_box} onChange={(e) => corporateCertificatesHandler(e, 'credit_policy_rules_box_corporate')} />
                    <span className="slider round"></span>
                  </label>
                  <p>Credit policy rules - Box</p>
                </div>
                {
                  ['credit_scoring_box', 'cashflow_box', 'category_expansion', 'overview'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.credit_decision_tab[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"credit_decision_tab"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
              </div>
            </div>
          </div>
          <div className="detailed-box">
            <h3 className="headeing-switch">Growth</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={growthTabAll} onChange={(e) => corporateCertificatesHandler(e, 'growth_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {/*===*/}
                {
                  ['revenues_chart', 'earnings_chart', 'growth_grade'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.growth[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"growth"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
                {/*===*/}
              </div>
            </div>
          </div>
          <div className="detailed-box">
            <h3 className="headeing-switch">Profitability</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={profitabilityTabAll} onChange={(e) => corporateCertificatesHandler(e, 'profitability_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {/*===*/}
                {
                  ['margin_chart', 'efficiency_chart', 'profitability_grade'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.profitability[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"profitability"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
                {/*===*/}
              </div>
            </div>
          </div>
          <div className="detailed-box">
            <h3 className="headeing-switch">Invoices</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={invoicesTabAll} onChange={(e) => corporateCertificatesHandler(e, 'invoices_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {/*===*/}
                {
                  ['customer_invoices', 'supplier_invoices', 'customer_spread_chart_table', 'supplier_spread_chart_table', 'payments_metric', 'invoices_table'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.invoices[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"invoices"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
                {/*===*/}
              </div>
            </div>
          </div>
          <div className="detailed-box">
            <h3 className="headeing-switch">Balance Sheet</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                <div className="select-switch">
                  <label className="switch-box">
                    <input type="checkbox" checked={balanceSheetTabAll} onChange={(e) => corporateCertificatesHandler(e, 'balance_sheet_tab_all')} />
                    <span className="slider round"></span>
                  </label>
                  <p>All</p>
                </div>
                {/*===*/}
                {
                  ['balance_sheet_chart', 'debt_flow_chart', 'debt_flow_accordion', 'balance_sheet_table', 'balance_sheet_grade_table'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.balance_sheet[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"balance_sheet"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
                {/*===*/}
              </div>
            </div>
          </div>
          <div className="detailed-box">
            <h3 className="headeing-switch">Income Statement</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                {
                  ['income_statement_table'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.income_statement[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"income_statement"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
                {/*===*/}
              </div>
            </div>
          </div>
          <div className="detailed-box">
            <h3 className="headeing-switch">Discrepancies</h3>
            <div className="head-switch">
              <div className="adjust-switcher">
                {
                  ['accounting_accuracy', 'discrepancies'].map((keys, index) => <ToggleOrgCertificate keys={keys} checked={clientDetails?.certificate?.corporate?.discrepancies[keys]} toggleFunction={corporateCertificatesHandler} certificateKey={"discrepancies"} capitalizeFirstLetter={capitalizeFirstLetter} key={index} />)
                }
                {/*===*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="create-client-button"><button onClick={() => submitHandle()}>Update</button></div>
    </div>
  );
}

export default CertificateTab;
