require("dotenv").config();
export const API_CONFIG = {
  BASE_AUTH_API_URL: process.env.REACT_APP_BASE_AUTH_API_URL,
  BASE_EXTERNAL_API_URL: process.env.REACT_APP_BASE_EXTERNAL_API_URL,
  API_PATH: "/api",
  ADMIN: "/admin",
  SUPER_ADMIN_PORTAL: "/super_admin_portal",
  API_VERSION_PATH: "/v1",
  MIDDLE_PATH: "/super_admin_portal",
  END_POINTS: {
    AUTH: {
      SIGN_IN: () => "/sessions",
      SIGN_OUT: () => "/sessions/signout",
      VERIFY_TFA: () => "/sessions/verify_tfa",
      GET_OTP_VIA: () => "/sessions/get_otp_via",
    },
    ITMENU: {
      LIST: () => "/organization_requests",
      FILTER: () => "/organization_requests/filter_data",
      DELETE: () => "/organization_requests/"
    },
    UPLOADOBJECTSTOAWS: {
      CREATE_UPLOAD_FILES: () => "/public_uploads",
      LIST: () => "/public_uploads",
      UPDATE: () => "/public_uploads/"
    },
    ORGANIZATION_USER: {
      FILTER: () => "/organization_users/get_org_users",
      UPDATE: () => "/organization_users/edit_org_user",
      DEACTIVATE: () => '/organization_users/deactivate',
      ACTIVATE: () => '/organization_users/activate',
      CREATE: () => '/organization/create_org_user_for_admin_app',
      INVITEUSER: () => '/organization_users/generate_invitation_link'
    },
    SERVICE_PROVIDER: {
      FILTER: () => "/service_providers",
      UPDATE: () => "/service_providers/",
    },
    ADMIN_LOGS: {
      LIST: () => "/admin_logs",
      REFETCH_EB_TRANSACTIONS: () => "/refetch_transactions",
      UPDATE_EB_TRANSACTIONS: () => "/update_transactions",
    },
    STATISTICS: {
      LIST: () => "/statistics",
      FILTER: () => "/statistics/get_filter_data",
      ACTIVE_CUSTOMERS: () => "/statistics/active_customers",
      PROVIDERS_MODES_DATA: () => "/statistics/providers_modes_data",
      VISTA_ACCOUNT_USERS: () => "/organization_users/active_users",
      CREATED_VS_ACTIVE_CUSTOMERS: () => "/created_vs_active_customers",
    },
    INVOICES_LOGS: {
      LIST: () => "/dashboard/invoicing_data",
      DETAILS: () => "/dashboard/invoicing_by_month",
      MORE_DETAILS: () => "/dashboard/more_details",
      GENERATE_REPORT: () => "/dashboard/create_report_for_invoice_details",
      INVOICES_DOWNLOAD_URL: () => "/dashboard/get_invoice_download_url",
      INVOICE_EXPORT: () => "/dashboard/generate_invoice_xlsx",
    },
    CHANGE_PASSWORD: {
      UPDATE_PASSWORD: () => "/sessions/change_password",
    },
    USER_MANAGEMENT: {
      LIST: () => "/users",
    },
    CREATE_USER: {
      CREATE: () => "/users",
    },
    SEARCH_USER: {
      SEARCH: () => "/users?query=",
    },
    DELETE_USER: {
      DELETE: () => "/users/",
    },
    EDIT_USER: {
      EDIT: () => "/users/",
    },
    LIVE_DATA: {
      FILTER: () => "/live_data/filter_data",
      DETAILS: () => "/live_data",
      RECENT_ACTIVITIES: () => "/live_data/recent_activities",
      REQUEST_BY_COUNTRY: () => "/live_data/request_by_countries",
      REQUEST_COUNT_ORGANIZATION: () => "/live_data/request_count_with_organization",
      REQUEST_COUNT_MARKETS: () => "/live_data/request_count_with_market",
      REQUEST_WORLDMAP: () => "/organization_requests/requests_worldmap",
      CUSTOMERS: () => "/stats/customers",
      BANKS: () => "/stats/banks",
      MARKETS: () => "/stats/markets",
      FILTER_CUSTOMERS: () => "/stats/filter_data",
    },
    TEST_CENTER: {
      LIST: () => "/test_centers",
      VALUES: () => "/test_centers/get_basic_values",
      DATE: () => "/test_centers?test_date_range=",
      EDIT: () => "/test_centers/",
      DELETE: () => "/test_centers/",
    },
    EMAIL_LOGS: {
      LIST: () => "/email_services/email_logs",
    },
    SMS_LOGS: {
      LIST: () => "/sms_services/sms_logs",
    },
    SEARCH_CERTIFICATEID: {
      SEARCHID: () => "/admin_logs/certificate_logs",
      SEARCH_ORG: () => '/admin_logs/certificate_search'
    },
    DASHBOARD: {
      INDEX: () => "/dashboard",
      GRAPH_DATA: () => "/dashboard_clients_graph_data",
      CLIENTS_DATA: () => "/dashboard_clients_data",
      CLIENT_DETAILS: () => "/dashboard_clients_organization_detail",
    },
    TOTAL_CLIENTS: {
      INDEX: () => "/total_clients",
      // CLIENTS_DATA: () => "/total_clients/total_clients_data",
      CLIENTS_DATA: () => "/dashboard/total_clients",
    },
    ORGANIZATION_PROVIDERS: {
      ORGANIZATION_PROVIDERS_LIST: () => "/organization_providers",
      BANK_PRIORITY_UPDATE: () => "/organization_providers/bank_priority_update",
      UPDATE: () => "/organization_providers/",
      DATAFILTER: () => "/organization_providers",
      CREATE: () => "/organization_providers/",
      DELETE: () => "/organization_providers/",
      EDIT_ORGANIZATION_PROVIDER_ICON: () => "/organization_providers/",
      DISPLAY_KREDITZ_PROVIDER: () => "/organization_providers/display_kreditz_provider"
    },
    ORGANIZATION_MANAGEMENT: {
      ORGANIZATION_LIST: () => "/organizations",
      ORGANIZATION_LIST_NO_ACTIVITY: () => "/organizations/organization_with_no_activity",
      DELETE: () => "/organizations/",
      CREATE: () => "/organizations",
      GET_INVITATION: () => "/organization_users/generate_invitation_link?id=",
      SEND_INVITATION_VIA_EMAIL: () => "/organization_users/send_invitation_via_email",
      UPDATE: () => "/organizations/",
      ORGANIZATION_BY_ID: () => "/organizations/find_by?",
      BANK_LIST: () => "/organization_providers/organization_bank_list",
      BANK_DETAILS: () => "/bank_lists/find_default_bank_message",
      CREATE_CUSTOM_BANK_MESSAGE: () => "/bank_lists/create_multi_bank_message",
      FIND_CUSTOM_BANK_MESSAGE: () => "/bank_lists/find_custom_bank_message",
      UPDATE_CUSTOM_BANK_MESSAGE: () => "/bank_lists/",
      ORGANIZATION_ACCOUNT_LIST: () => "/organizations/organizations_list",
      SERVICE_PROVIDERS_LIST: () => '/services',
      ORGANIZATION_PROVIDER_DELETE: () => '/organization_providers/',
      ORGANIZATION_BROKER_DETAILS: () => "/organizations/fetch_broker_organization_details",
      BROKER_FLOW_EXPORT: () => "/organizations/generate_broker_flow_xlsx",
      DOWNLOAD_SETTINGS:(id)=>`/settings/${id}`
    },
    ORGANIZATION_EMAIL_SMS: {
      LIST_ALL_SMS_TEMPLATES: () => "/sms_templates/all_sms_templates",
      FETCH_SMS_TEMPLATE: () => "/sms_templates/fetch_template",
      FETCH_SMS_TEMPLATE_PREVIEW: () => "/sms_templates/preview_default",
      CREATE_SMS_TEMPLATE: () => "/sms_templates",
      UPDATE_SMS_TEMPLATE: (id) => `/sms_templates/${id}`,
      LIST_ALL_EMAIL_TEMPLATES: () => "/email_templates/all_email_templates",
      FETCH_EMAIL_TEMPLATE: () => "/email_templates/fetch_template",
      FETCH_EMAIL_TEMPLATE_PREVIEW: () => "/email_templates/preview_default",
      CREATE_EMAIL_TEMPLATE: () => "/email_templates",
      UPDATE_EMAIL_TEMPLATE: (id) => `/email_templates/${id}`,
    },
    ORGANIZATION_CONSENT_TEXTS: {
      INDEX: () => "/texts",
      CREATE: () => '/texts',
      DELETE: () => '/texts',
      UPDATE: () => '/texts',
      FILTERS: () => '/texts/filter_data',
      SHOW: () => '/texts'
    },
    AUDIT_REPORT: {
      LIST: () => "/audits",
      DETAILS: () => "/audits",
      PDFREPORT: () => "/admin_logs/audit_report"
    },
    DEMO_AGGREGATION: {
      LIST: () => "/demo_access_codes",
      GENERATE: () => "/generate_access_code",
      SEND: () => "/send_access_code",
    },
    REPORT: {
      LIST: () => "/reports",
    },
    Conversion_rate: {
      LIST: () => "/conversion_rates",
      FILTER: () => "/conversion_rates/filter_data",
      GENERATE_XLSX: () => "/conversion_rates/generate_xlsx_file",
      BANKS_FILTER: () => "/conversion_rates/banks_filter_data"
    },
    SERVICE_SETTING: {
      LIST: () => "/service_settings",
      FILTER: () => "/service_settings/filter_data",
      UPDATE: () => "/service_settings/",
      CREATE: () => "/service_settings/",
    },
    KREDITZ_PROVIDER: {
      KREDITZ_PROVIDER_LIST: () => "/kreditz_providers",
      SAVEFILE: () => "/kreditz_providers/",
    },
    COUNTRIES: {
      LIST: () => "/countries",
    },
    FORGET_PASSWORD: () => "/sessions/forget_password",
    RESET_PASSWORD: () => "/sessions/reset_password",
    NEW_PASSWORD_TOKEN_VALIDATION: () => "/sessions/new_password_token_validation",
    NEW_PASSWORD_EMAIL_VALIDATION: () => "/sessions/new_password_email_validation",
    NEW_PASSWORD_SET: () => "/sessions/set_password",
    BANK_CONNECTION_FREQUENCY: {
      LIST: () => "/statistics/bcf_stats",
    },
    COMPARISON_REPORT: {
      LIST: () => "/comparison_reports",
      //FILTER: () => "/comparison_reports/filter_data" ????
    },
    DYNAMIC_LIVE_DATA: {
      LIST: () => "/dynamic_live_data",
      FILTER: () => "/dynamic_live_data/filter_data",
    },
    BANNERS: {
      LIST: () => "/banners",
      CREATE: () => "/banners",
      UPDATE: () => "/banners",
      DELETE: () => "/banners",
    },
    API_ACCESS_CODES: () => "/access_codes",
    CERTIFICATE_INFORMATION: {
      LIST: () => "/admin_logs/certificate_information",
      FILTER: () => "/admin_logs/certificate_filters"
    },
    BANK_STATUS: {
      LIST: () => "/bank_status"
    },
    HEALTH_CHECK: {
      LIST: () => "/healthcheck",
      UPDATE: () => "/healthcheck/"
    },
    EVENT_LOGS: {
      LIST: () => "/event_logs"
    },
    BACKGROUND_JOBS: {
      GET: () => "/background_jobs",
      GET_HISTORY: (jobId) => `/background_jobs/${jobId}/history`,
      POST: () => "/background_jobs",
      UPDATE: (jobId) => `/background_jobs/${jobId}`,
    },
    API_DOCUMENTATION: {
      LIST: () => "/api_documentations",
      SETTING: () => "/api_documentation_settings",
    },
    CONFIGURATION_SETTINGS: {
      LIST: () => "/configuration_settings",
      UPDATE: () => "/configuration_settings/",
    },
    ORGANIZATIONS_ENDPOINTS: {
      LIST: () => "/analytics_endpoints",
      CREATE: () => "/analytics_endpoints",
      UPDATE: (id) => `/analytics_endpoints/${id}`,
      PING: (id) => `/analytics_endpoints/${id}/ping_endpoint`,
      REQUESTS: (id) => `/analytics_endpoints/${id}/requests`,
    },
  },
};