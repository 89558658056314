import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CreatedVsActiveClientsPieChart = ({ createdClients, activeClients }) => {
  const options = {
    chart: {
      type: "pie",
      style: {
        fontFamily: "SF Pro Display",
        color: "#8591A6",
      },
    },
    credits: {
      enabled: false
    },
    title: {
      text: "Created clients vs Active clients",
      align: "left",
      margin: 20,
      style: {
        fontWeight: 500,
        color: "#596980",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.name} <br/> <span style="color: #8591a6">{point.percentage:.1f}%</span>',
          distance: 20,
        },
        showInLegend: true
      }
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    series: [{
      name: 'Clients',
      colorByPoint: true,
      data: [
        {
          name: 'Created Clients',
          y: createdClients,
          color: '#2196F3'
        },
        {
          name: 'Active Clients',
          y: activeClients,
          color: '#4CAF50'
        }
      ]
    }]
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options} />
    </div>
  );
};

export default CreatedVsActiveClientsPieChart;
