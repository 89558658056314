import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import CreatedVsActiveOverview from "../../Components/Statistics/CreatedVsActiveOverview/CreatedVsActiveOverview";

import "./style.css";
const CreatedVsActiveOverviewPage = () => {
  useEffect(() => {
    document.title = "Kreditz | Admin portal - Created vs Active overview";
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <CreatedVsActiveOverview />
      </div>
    </div>
  );
};

export default CreatedVsActiveOverviewPage;