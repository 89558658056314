import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CreatedVsActiveClientsChart = ({ data }) => {
  const yearData = data || [];

  const options = {
    chart: {
      type: "column",
      height: 350,
      style: {
        fontFamily: "SF Pro Display",
        color: "#8591A6",
      },
    },
    credits: {
      enabled: false
    },
    title: {
      text: "Created clients vs Active clients",
      align: "left",
      margin: 40,
      style: {
        fontWeight: 500,
        color: "#596980",
      },
    },
    xAxis: {
      categories: yearData.map(item => item.date),
      labels: {
        rotation: -40,
        style: {
          fontSize: '11px'
        }
      }
    },
    yAxis: {
      title: {
        text: 'Total Clients'
      },
      min: 0
    },
    series: [
      {
        name: '<span style="color: #333333; cursor: pointer; font-size: 12px; font-weight: 550;">Created Clients</span>',
        data: yearData.map(item => item.created_clients),
        color: '#2196F3',
        dataLabels: {
          color: '#596980',
        }
      },
      {
        name: '<span style="color: #333333; cursor: pointer; font-size: 12px; font-weight: 560;">Active Clients</span>',
        data: yearData.map(item => item.active_clients),
        color: '#4CAF50',
        dataLabels: {
          color: '#596980',
        }
      }
    ],
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom'
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        pointWidth: 15,
        groupPadding: 0.25,
        borderRadius: 5,
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5
      },
      line: {
        dashStyle: "LongDash",
      },
      series: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold"
          },
          allowOverlap: true,
          crop: false,
          overflow: 'allow',
          rotation: -90,
          y: -10,
          x: 0,
          formatter: function () {
            return Highcharts.numberFormat(this.y, 0, ' ');
          },
        },
        minPointLength: 2,
        groupPadding: 0.4,
        pointPadding: 0.2,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default CreatedVsActiveClientsChart;