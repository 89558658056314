import axios from "axios";
import { API_CONFIG } from '../../config/api';
import { SET_STATISTICS_LIST, SET_STATISTICS_FILTER, SET_ACTIVE_CUSTOMERS, SET_PROVIDERS_MODES_DATA, SET_VISTA_ACCOUNT_USERS } from '../../Redux/Actions/Types/Types';
import { removeHeaderWithLocalstorage } from '../Actions/Auth';

export function getStatisticsData(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.STATISTICS.LIST()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, message: res.data.status, data: res.data.data };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message };
        });
}

export function getStatisticsFilter(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.STATISTICS.FILTER()}` + data;
    return dispatch => axios.get(apiURL, data)
        .then(res => {
            return { status: true, message: res.data.status, data: res.data.data };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message };
        });
}

export function getActiveCustomersData(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.STATISTICS.ACTIVE_CUSTOMERS()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, message: res.data.status, data: res.data.data };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message };
        });
}

export function getProvidersModesData(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.STATISTICS.PROVIDERS_MODES_DATA()}` + data;
    return dispatch => axios.get(apiURL)
        .then(res => {
            return { status: true, message: res.data.status, data: res.data.data };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message };
        });
}

export function getVistaAccountUsersData(data) {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.STATISTICS.VISTA_ACCOUNT_USERS()}`;
    return dispatch => axios.get(apiURL, data)
        .then(res => {
            return { status: true, message: res.data.status, data: res.data.data };
        })
        .catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message };
        });
}


export function setStatisticsList(response) {
    return {
        type: SET_STATISTICS_LIST,
        statistics_list: response
    }
}

export function setStatisticsFilter(response) {
    return {
        type: SET_STATISTICS_FILTER,
        statistics_list: response
    }
}

export function setActiveCustomers(response) {
    return {
        type: SET_ACTIVE_CUSTOMERS,
        statistics_list: response
    }
}

export function setProvidersModesData(response) {
    return {
        type: SET_PROVIDERS_MODES_DATA,
        statistics_list: response
    }
}

export function setVistaAccountUsers(response) {
    return {
        type: SET_VISTA_ACCOUNT_USERS,
        statistics_list: response
    }
}

export function getCreatedVsActiveCustomersData() {
    const apiURL = `${API_CONFIG.BASE_EXTERNAL_API_URL}${API_CONFIG.API_PATH}${API_CONFIG.ADMIN}${API_CONFIG.API_VERSION_PATH}${API_CONFIG.END_POINTS.DASHBOARD.INDEX()}${API_CONFIG.END_POINTS.STATISTICS.CREATED_VS_ACTIVE_CUSTOMERS()}`;
    return dispatch => axios.get(apiURL)
        .then(res => {
            if (!res.data) {
                return { status: false, message: 'Invalid response received' };
            }
            const success = res.data.status !== 'error';
            return {
                status: success,
                message: res.data.message || 'Success',
                data: res.data
            };
        }).catch(err => {
            (err.response.status === 401) && removeHeaderWithLocalstorage();
            return { status: false, message: err.response.data.error || err.response.data.message }
        })
}